import GoldCoinsImg from "assets/img/coinIcons/small.png";
import FliffCashImg from "assets/img/FliffCashVertical.png";
import { useSelector } from "react-redux";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { getGoldCoinsBalance } from "selectors/sportsBook";
import {
  CashAmountFormatters,
  CoinsAmountFormatters,
} from "utils/UIAmountsFormatters";
import "./styles.scss";
import { IProps } from "./types";

const FliffCurrenciesBox = ({ fliffCashAmount }: IProps) => {
  const coinsBalance = useSelector(getGoldCoinsBalance);
  const totalFliffCashBalance = useSelector(
    CoreSelectors.Balances.totalFliffCashBalance,
  );
  return (
    <section className="currencies-box">
      <div className="currencies-box__wrapper">
        <p className="currencies-box__amount">
          <img
            src={GoldCoinsImg}
            alt="Fliff cash"
            className="currencies-box__icon"
          />
          {CoinsAmountFormatters.toGoldCoins(coinsBalance)}
        </p>
        <p className="currencies-box__label">Fliff Coins Balance</p>
      </div>
      {fliffCashAmount > 0 && (
        <div className="currencies-box__wrapper">
          <p className="currencies-box__amount currencies-box__amount--cash">
            <img
              src={FliffCashImg}
              alt="Fliff cash"
              className="currencies-box__icon currencies-box__icon--cash"
            />
            {CashAmountFormatters.toFliffCash(totalFliffCashBalance)}
          </p>
          <p className="currencies-box__label currencies-box__label--cash">
            Fliff Cash Balance
          </p>
        </div>
      )}
    </section>
  );
};

export default FliffCurrenciesBox;
