import { useCallback, useEffect, useState } from "react";
import { PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Constants from "constants/Constants";
import ChannelUtils from "utils/ChannelUtils";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { Data__SB_Channel } from "server/legacyCore/data/objects";
import { HeraldBackend } from "server/herald/HeraldBackend";
import { staticGetState } from "reduxLocal/store";
import { FeedFocusUtils } from "utils/FeedFocusUtils";
import SportsChannelsContext from "./SportsChannelsContext";
import { MemoryStorage } from "utils/MemoryStorage";

const SportsChannelsProvider = ({ children }: PropsWithChildren) => {
  const history = useHistory();
  const location = useLocation();

  const [selectedChannelId, setSelectedChannelId] = useState<number>(() => {
    const params = new URLSearchParams(location.search);
    return Number(params.get("channelId")) || Constants.homeChannelId;
  });

  const [selectedSubChannelId, setSelectedSubChannelId] = useState<number>(
    () => {
      const params = new URLSearchParams(location.search);
      return Number(params.get("liveChannelId")) || 0;
    },
  );

  const [catchallChannel, setCatchallChannel] =
    useState<Data__SB_Channel | null>(null);

  const updateUrlSilently = useCallback(
    (channelId: number, subChannelId?: number) => {
      if (!location.pathname.startsWith("/sports")) {
        return;
      }

      const newUrl = new URL(window.location.href);
      const params = new URLSearchParams(newUrl.search);

      params.set("channelId", channelId.toString());

      if (subChannelId) {
        params.set("liveChannelId", subChannelId.toString());
      } else {
        params.delete("liveChannelId");
      }

      newUrl.search = params.toString();
      history.replace(newUrl.pathname + newUrl.search);
    },
    [history, location.pathname],
  );

  useEffect(() => {
    const subChannelId =
      selectedSubChannelId > 0 ? selectedSubChannelId : undefined;
    updateUrlSilently(selectedChannelId, subChannelId);
  }, [selectedChannelId, selectedSubChannelId, updateUrlSilently]);

  const handleSelectChannelFromFeed = useCallback(
    (id: number): number =>
      requestAnimationFrame(() => setSelectedChannelId(id)),
    [],
  );

  const handleSelectCatchallChannel = useCallback(
    (id: number): number => {
      const channelFromAllSports = ChannelUtils.getChannelById(
        CoreSelectors.Channels.allSportsChannels(staticGetState()),
        id,
      );
      const isChannelExistsInFeedChannel = ChannelUtils.getChannelById(
        CoreSelectors.Channels.dashboardChannels(staticGetState()),
        id,
      );
      if (channelFromAllSports && !isChannelExistsInFeedChannel) {
        return requestAnimationFrame(() => {
          setSelectedChannelId(id);
          setCatchallChannel(channelFromAllSports);
        });
      }
      return handleSelectChannelFromFeed(id);
    },
    [handleSelectChannelFromFeed],
  );

  const subscribeForChannelId = useCallback((channelId: number) => {
    const prevChannelId = MemoryStorage.focusedChannelId;

    if (prevChannelId) {
      HeraldBackend.unsubscribeForChannelId(prevChannelId);
    }

    if (channelId) {
      MemoryStorage.setFocusedChannelId(channelId);
      HeraldBackend.subscribeForChannelId(channelId);
    }
  }, []);

  const handleSubChannelSelect = useCallback(
    (id: number): void => {
      if (id !== 0) {
        subscribeForChannelId(id);
      }

      requestAnimationFrame(() => setSelectedSubChannelId(id));
    },
    [subscribeForChannelId],
  );

  const handleChannelSelect = useCallback(
    async (id: number, fromAllSports?: boolean): Promise<void | number> => {
      const isLiveChannel = id === Constants.liveChannelId;
      const isOffersChannel = id === Constants.offersChannelId;

      // Reset subChannel if switching to any non-live channel
      if ((!isLiveChannel || !isOffersChannel) && selectedSubChannelId) {
        handleSubChannelSelect(0);
      }

      if (fromAllSports) {
        subscribeForChannelId(id);
        try {
          const isError =
            await FeedFocusUtils.handleSelectChannelFromAllSports();
          if (isError) {
            HeraldBackend.unsubscribeForChannelId(id);
            handleChannelSelect(Constants.homeChannelId);
            return;
          }
        } catch (error) {
          HeraldBackend.unsubscribeForChannelId(id);
          handleChannelSelect(Constants.homeChannelId);
          return;
        }
        handleSelectCatchallChannel(id);

        requestAnimationFrame(() => {
          history.replace(`/sports?channelId=${id}`);
        });
        return;
      } else {
        handleSelectChannelFromFeed(id);
      }

      if (id === Constants.homeChannelId) {
        return subscribeForChannelId(id);
      }

      if (isLiveChannel || isOffersChannel) {
        const subChannels = isOffersChannel
          ? CoreSelectors.Channels.offersChannels(staticGetState())
          : CoreSelectors.Channels.liveChannels(staticGetState());
        const firstSubChannelId = subChannels[0]?.id;

        if (firstSubChannelId) {
          handleSubChannelSelect(firstSubChannelId);
        } else {
          subscribeForChannelId(id);
        }
        return;
      }

      subscribeForChannelId(id);
    },
    [
      handleSelectCatchallChannel,
      selectedSubChannelId,
      handleSelectChannelFromFeed,
      handleSubChannelSelect,
      subscribeForChannelId,
      history,
    ],
  );

  return (
    <SportsChannelsContext.Provider
      value={{
        handleChannelSelect,
        selectedChannelId,
        handleSubChannelSelect,
        selectedSubChannelId,
        catchallChannel,
      }}>
      {children}
    </SportsChannelsContext.Provider>
  );
};

export default SportsChannelsProvider;
