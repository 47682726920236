import * as sportsBookS from "selectors/sportsBook";
import { PersistentStorage } from "utils/PersistentStorage";

import {
  Data__SB_User_Personal_Info,
  IDataSlideGiftCardBrand,
} from "server/legacyCore/data/objects";
import * as H from "history";
import AppSelectors from "reduxLocal/app/app.selectors";
import { AppUIHideModalProgressIndicator } from "reduxLocal/appUI/appUI.actions";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { staticGetState } from "reduxLocal/store";
import { FliffException } from "server/common/FliffException";
import { SportsBookBIF } from "server/legacyCore/server/SportsBookBIF";
import { SportsBookSignInBIF } from "server/legacyCore/server/SportsBookSignInBIF";
import {
  IdentityVerificationStatus,
  UserAccountDetailsStatus,
} from "server/legacyCore/data/constants";
import { TIdentityVerificationMode, TVerifyPhoneNumberMode } from "src/types";
import MixPanelShared from "utils/Analytics/MixPanelWrapper/MixPanelShared";
import { DevConstants } from "src/DevConstants";
import { isMobileOnly } from "react-device-detect";

class ProfileVerificationUtils {
  public handlePhoneNumberSuccessVerificationSubmit = async (
    mode: TVerifyPhoneNumberMode,
    history: H.History,
    userId: number | undefined,
  ): Promise<void> => {
    if (mode === 0) {
      history.push("/settings");
      return;
    }
    const response =
      await SportsBookSignInBIF.blockingAuthenticateWithSavedTokensOnStartup();
    if (response.is_error) {
      if (FliffException.isProfileNotCompletedError(response.exception)) {
        history.replace("/complete-profile");
      } else if (
        FliffException.isPrimaryPhoneNumberRequired(response.exception)
      ) {
        history.replace("/verify-phone", { mode: 1 });
      } else if (
        FliffException.isTermsOfUseAcceptanceRequired(response.exception)
      ) {
        history.replace("/terms-of-use");
      }
      return;
    }
    if (mode === 1) {
      // -1 unknown user ID – profile for some reason is now available yet.
      // Still not save from my (Roman) point of view. Has to be tracked on lower level.
      // API core must be reconsidered (refactored).
      MixPanelShared.trackSignUp(userId || -1);
    }
    if (response.is_error) {
      return;
    }
    AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
    history.replace("/sports");
  };

  // 2022-01-29 / Ivan / extract local properties and prepare object for server
  public extractPersonalInfo(
    userId: number | null,
  ): Data__SB_User_Personal_Info {
    const personalInfo = Data__SB_User_Personal_Info.EMPTY;

    const persistedWithdrawInfo = PersistentStorage.getTempWithdrawInfo(userId);
    if (!persistedWithdrawInfo) {
      return personalInfo;
    }

    if (persistedWithdrawInfo.firstName) {
      personalInfo.first_name = persistedWithdrawInfo.firstName;
    }
    if (persistedWithdrawInfo.lastName) {
      personalInfo.last_name = persistedWithdrawInfo.lastName;
    }
    if (persistedWithdrawInfo.dateOfBirth) {
      personalInfo.date_of_birth = persistedWithdrawInfo.dateOfBirth;
    }

    personalInfo.country_code = "USA";
    if (persistedWithdrawInfo.state) {
      personalInfo.country_state = persistedWithdrawInfo.state;
    }
    if (persistedWithdrawInfo.zipCode) {
      personalInfo.zip_code = persistedWithdrawInfo.zipCode;
    }
    if (persistedWithdrawInfo.city) {
      personalInfo.city = persistedWithdrawInfo.city;
    }
    if (persistedWithdrawInfo.address1) {
      personalInfo.address_1 = persistedWithdrawInfo.address1;
    }
    if (persistedWithdrawInfo.address2) {
      personalInfo.address_2 = persistedWithdrawInfo.address2;
    }

    return personalInfo;
  }

  public handleVerificationPress = (
    routeName: string,
    history: H.History,
    dismissReset?: boolean,
  ): void => {
    const state = staticGetState();
    const identityVerificationStatus =
      sportsBookS.getIdentityVerificationStatus(state);

    if (this.checkIsIdentityVerificationPending(identityVerificationStatus)) {
      history.push("/verify-id-pending", {
        verificationMode: 0,
      });
      return;
    }
    const primaryEmailState = CoreSelectors.Attributes.emailState(state);
    const primaryPhoneNumberState = CoreSelectors.Attributes.phoneState(state);
    const personalDetailsVerificationStatus =
      sportsBookS.getPersonalDetailsVerificationStatus(state);

    const isProfileVerified = this.getIsProfileVerified(
      primaryEmailState,
      primaryPhoneNumberState,
      personalDetailsVerificationStatus,
      identityVerificationStatus,
    );
    if (isProfileVerified) {
      const isNextWithdrawAvailable =
        CoreSelectors.Withdrawal.isWithdrawalAvailable(state);
      if (isNextWithdrawAvailable) {
        if (!dismissReset) {
          history.replace("/make-withdraw");
        }
        history.push("/make-withdraw");
        return;
      }

      if (!dismissReset) {
        history.replace("/unavailable-withdraw");
      }
      history.push("/unavailable-withdraw");
      return;
    }
    history.push(routeName);
  };

  public handlePersonalDetailsVerification = async (
    history: H.History,
    redirect?: boolean,
  ) => {
    if (isMobileOnly) {
      const { is_error: isError, resultObject } =
        await SportsBookBIF.blocking_initiate_persona_verify({
          verification_mode: 3,
        });
      if (isError) {
        return;
      }

      history.push("/verify-profile", {
        persona_reference_id: resultObject!.persona_reference_id,
        persona_template_id: resultObject!.persona_template_id,
        redirect,
      });
    } else {
      history.push("/settings/verify-profile", {
        type: "purchase",
      });
    }
  };

  public handleIdentityVerificationInit = async (
    mode: 1 | 2,
    history: H.History,
    withoutHistoryReplace?: boolean,
  ): Promise<void> => {
    const { is_error: isError, resultObject } =
      await SportsBookBIF.blocking_initiate_persona_verify({
        verification_mode: mode,
      });

    if (isError) {
      return;
    }
    const navigate = withoutHistoryReplace ? history.push : history.replace;

    navigate("/verify-id-frame", {
      persona_reference_id: resultObject!.persona_reference_id,
      persona_template_id: resultObject!.persona_template_id,
      verificationMode: mode,
    });
  };

  public checkIsIdentityVerified = (
    status: IdentityVerificationStatus,
  ): boolean =>
    status === IdentityVerificationStatus.CONST_7001_LEVEL_1 ||
    status === IdentityVerificationStatus.CONST_7002_LEVEL_2;

  public isPersonalDetailsVerified = (
    status: UserAccountDetailsStatus,
  ): boolean =>
    status ===
    UserAccountDetailsStatus.CONST_30121_ACCOUNT_DETAILS_COMPLETED_READ_ONLY;

  public getIsProfileVerified = (
    primaryEmailState: number,
    primaryPhoneNumberState: number,
    personalDetailsVerificationStatus: UserAccountDetailsStatus,
    identityVerificationStatus: IdentityVerificationStatus,
  ): boolean => {
    if (DevConstants.isVerifyIdentityDisabled) {
      return true;
    }

    const isPrimaryEmailVerified =
      this.checkIsProfileAttributeVerified(primaryEmailState);
    const isPrimaryPhoneNumberVerified = this.checkIsProfileAttributeVerified(
      primaryPhoneNumberState,
    );
    const isPersonalDetailsVerified = this.isPersonalDetailsVerified(
      personalDetailsVerificationStatus,
    );
    const isIdentityVerified = this.checkIsIdentityVerified(
      identityVerificationStatus,
    );

    return (
      isPrimaryEmailVerified &&
      isPrimaryPhoneNumberVerified &&
      isPersonalDetailsVerified &&
      isIdentityVerified
    );
  };

  public checkIsIdentityVerifiedLevel1 = (
    status: IdentityVerificationStatus,
  ): boolean => status === IdentityVerificationStatus.CONST_7001_LEVEL_1;
  public checkIsIdentityVerifiedLevel2 = (
    status: IdentityVerificationStatus,
  ): boolean => status === IdentityVerificationStatus.CONST_7002_LEVEL_2;
  public checkIsProfileAttributeUnverified = (state: number): state is -1 =>
    state === -1;
  public checkIsProfileAttributeClaimed = (state: number): state is 0 =>
    state === 0;
  public checkIsProfileAttributeVerified = (state: number): state is 1 =>
    state > 0;

  public checkIsIdentityVerificationPending = (
    status: IdentityVerificationStatus,
  ): boolean =>
    // We assume that until user is approved (l1/l2) - it's a pending state.
    status === IdentityVerificationStatus.CONST_7013_UNDER_REVIEW ||
    status === IdentityVerificationStatus.CONST_7011_BANNED ||
    status === IdentityVerificationStatus.CONST_7012_REJECTED_BY_PERSONA;

  public getIdentityVerificationStatusByMode = (
    verificationMode: TIdentityVerificationMode,
    identityVerificationStatus: IdentityVerificationStatus,
  ): boolean => {
    if (verificationMode === 0) {
      return this.checkIsIdentityVerified(identityVerificationStatus);
    }
    if (verificationMode === 1) {
      return this.checkIsIdentityVerifiedLevel1(identityVerificationStatus);
    }
    if (verificationMode === 2) {
      return this.checkIsIdentityVerifiedLevel2(identityVerificationStatus);
    }
    return false;
  };

  public handleSlideStorePress = (
    inputStore: IDataSlideGiftCardBrand,
    history: H.History,
  ): void => {
    const state = staticGetState();
    const isAuthenticated = AppSelectors.isAuthenticated(state);
    MixPanelShared.trackOffersBrandsPress(inputStore.b_name);

    if (!isAuthenticated) {
      history.push("/sign-in");
      return;
    }

    const personalDetailsVerificationStatus =
      sportsBookS.getPersonalDetailsVerificationStatus(state);
    if (!this.isPersonalDetailsVerified(personalDetailsVerificationStatus)) {
      history.push("/verify-id-home", {
        type: "purchase",
      });
      return;
    }

    if (sportsBookS.getIsPurchaseProcessing(state)) {
      history.push(`/purchase/progress/${inputStore.b_uuid}`);
      return;
    }

    const isStoreWithStrictAmounts = inputStore.cvc_increment !== 1;

    if (isStoreWithStrictAmounts) {
      history.push("/available-offers-purchase", {
        store: inputStore,
      });
      return;
    }

    history.push("/offers-purchase", { store: inputStore });
  };
}

export default new ProfileVerificationUtils();
