import { IProps } from "./types";
import "./styles.scss";

import { useState, useRef, useEffect, useCallback, CSSProperties } from "react";

const CustomTooltip = ({
  content,
  position = "top",
  interactive = false,
  showArrow,
  children,
}: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<CSSProperties>({});
  const [arrowStyle, setArrowStyle] = useState<CSSProperties>({});
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  const toggleTooltip = () => {
    if (interactive) {
      setIsVisible(prev => !prev);
    }
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      hideTooltip();
    }
  }, []);

  useEffect(() => {
    if (interactive && isVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [interactive, isVisible, handleClickOutside]);

  const updateTooltipPosition = useCallback(() => {
    if (!triggerRef.current || !tooltipRef.current) {
      return;
    }

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const style: CSSProperties = {};
    const newArrowStyle: CSSProperties = {};

    switch (position) {
      case "top":
        style.top = `${triggerRect.top - tooltipRect.height - 40}px`;
        style.left = `15px`;
        newArrowStyle.bottom = "-6px";
        newArrowStyle.left = `${triggerRect.left + triggerRect.width}px`;
        newArrowStyle.transform = "rotate(45deg)";
        break;
      case "bottom":
        style.top = `${triggerRect.bottom - 75}px`;
        style.left = `15px`;
        newArrowStyle.top = "-6px";
        newArrowStyle.left = `${triggerRect.left - triggerRect.width}px`;
        newArrowStyle.transform = "rotate(45deg)";
        break;
      case "left":
        style.top = `${
          triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2
        }px`;
        style.left = `${triggerRect.left - tooltipRect.width - 8}px`;
        newArrowStyle.right = "-6px";
        newArrowStyle.top = "50%";
        newArrowStyle.transform = "translateY(-50%) rotate(45deg)";
        break;
      case "right":
        style.top = `${
          triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2
        }px`;
        style.left = `${triggerRect.right + 8}px`;
        newArrowStyle.left = "-6px";
        newArrowStyle.top = "50%";
        newArrowStyle.transform = "translateY(-50%) rotate(45deg)";
        break;
      default:
        break;
    }

    setTooltipStyle(style);
    setArrowStyle(newArrowStyle);
  }, [position]);

  useEffect(() => {
    if (isVisible) {
      updateTooltipPosition();
    }
  }, [isVisible, updateTooltipPosition]);

  return (
    <div
      className="tooltip__trigger"
      onMouseEnter={!interactive ? showTooltip : undefined}
      onMouseLeave={!interactive ? hideTooltip : undefined}
      onClick={interactive ? toggleTooltip : undefined}
      ref={triggerRef}>
      {children}
      {isVisible && (
        <>
          <div
            ref={tooltipRef}
            className="tooltip__content"
            style={tooltipStyle}
            onMouseEnter={interactive ? showTooltip : undefined}
            onMouseLeave={interactive ? hideTooltip : undefined}>
            {content}
            {showArrow && <div className="tooltip__arrow" style={arrowStyle} />}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomTooltip;
