import { AxiosResponse } from "axios";
import { DevConstants } from "src/DevConstants";
import { AppUtils } from "utils/AppUtils";
import { PersistentStorage } from "utils/PersistentStorage";
import { FliffException } from "server/common/FliffException";
import { ILocalAxiosRequestConfig } from "server/legacyCore/data/objectsCore";
import CoreApiUrlManager from "server/common/CoreApiUrlManager";

const DEFAULT_PROD_SOCIAL_SERVER_BASE_URL = "https://fsc.app.getfliff.com/";
const DEFAULT_DEV_SOCIAL_SERVER_BASE_URL = "https://fsc.dev.corp.getfliff.com/";
const DEFAULT_STAG_SOCIAL_SERVER_BASE_URL =
  "https://fsc.stg.corp.getfliff.com/";
const DEFAULT_DEV_CORE_SERVER_BASE_URL = "https://fcw.dev.corp.getfliff.com/";
const DEFAULT_STAG_CORE_SERVER_BASE_URL = "https://fcw.stg.corp.getfliff.com/";
const DEFAULT_PROD_CORE_SERVER_BASE_URL = "https://app.getfliff.com/";

const APP_PROFILE_DEFAULT_OPTIONS = {
  appProfileCode: "appProfileCode",
  visualName: "visualName",
  base_url: "base_url",
  social_server_base_url: DEFAULT_PROD_SOCIAL_SERVER_BASE_URL,
  client_id: "client_id",
  client_secret: "client_secret",
  inject_social_nasty: false,
  inject_nasty: false,
  inject_network_post_response_error: false,
  inject_network_delays_0: false,
  inject_network_delays_1: false,
  inject_network_delays_2: false,
  inject_random_network_errors_1: false,
  inject_x_errors: false,
  inject_login_credentials_qa_dev: false,
  inject_login_credentials_qa_qa: false,
  inject_login_credentials_qa_stg: false,
  inject_network_delay_in_millis: 0,
};

type TAppProfile = typeof APP_PROFILE_DEFAULT_OPTIONS;

export const APP_PROFILE_PROD: TAppProfile = {
  ...APP_PROFILE_DEFAULT_OPTIONS,
  // prod
  appProfileCode: "prod",
  visualName: "prod",
  base_url: DEFAULT_PROD_CORE_SERVER_BASE_URL,
  client_id: "cZTpXc522vdcLi9SSLrNXCSO0UOxy0OLX29novY9",
  client_secret:
    "IZotcylyS76FWNrLsZm7KP0KlwLIlGbpkQuTSCLDnXzbIdFGJ0mERa4q2X6uSJdn8vlJpZ6DEZ6sIP0f1J2l64jKe8uTD2BkRprTiUBvbihZaD56AQ6O5GyzCqUNFolL",
};

const APP_PROFILE_STAGING: TAppProfile = {
  ...APP_PROFILE_DEFAULT_OPTIONS,
  // staging
  appProfileCode: "staging",
  visualName: "staging",
  base_url: DEFAULT_STAG_CORE_SERVER_BASE_URL,
  client_id: "cZTpXc522vdcLi9SSLrNXCSO0UOxy0OLX29novY9",
  client_secret:
    "IZotcylyS76FWNrLsZm7KP0KlwLIlGbpkQuTSCLDnXzbIdFGJ0mERa4q2X6uSJdn8vlJpZ6DEZ6sIP0f1J2l64jKe8uTD2BkRprTiUBvbihZaD56AQ6O5GyzCqUNFolL",
};

const APP_PROFILE_DEV: TAppProfile = {
  ...APP_PROFILE_DEFAULT_OPTIONS,
  // dev
  appProfileCode: "dev",
  visualName: "dev",
  base_url: DEFAULT_DEV_CORE_SERVER_BASE_URL,
  social_server_base_url: DEFAULT_DEV_SOCIAL_SERVER_BASE_URL,
  client_id: "NW0tQ7NMuXaenET3XSSz5STSq1CTOjvX72obgysf",
  client_secret:
    "ZHWetB5AHrlVfqPN5UxgoI6lhV1eYMY4ZB5O0Np8RWBVbgLfxc2UMkH9lsSsKvcrhqTX0O014AqSNZsK4iJ9QyuxImZ5lYqivkmrvgvgsdxuSe4avs5Ldd2446giqfEz",
};
const APP_PROFILE_PROD_STAGING_SOCIAL: TAppProfile = {
  ...APP_PROFILE_PROD,
  social_server_base_url: DEFAULT_STAG_SOCIAL_SERVER_BASE_URL,
  appProfileCode: "prod_staging_social",
  visualName: "prod (staging social)",
};

const DW_PROFILES: TAppProfile[] = Array.from({ length: 50 }).map(
  (_, index) => {
    const nextIndex = index + 1;
    const profile = `dw5${nextIndex <= 9 ? `0${nextIndex}` : nextIndex}`;

    return {
      ...APP_PROFILE_DEFAULT_OPTIONS,
      social_server_base_url: DEFAULT_DEV_SOCIAL_SERVER_BASE_URL,
      client_id: "NW0tQ7NMuXaenET3XSSz5STSq1CTOjvX72obgysf",
      client_secret:
        "ZHWetB5AHrlVfqPN5UxgoI6lhV1eYMY4ZB5O0Np8RWBVbgLfxc2UMkH9lsSsKvcrhqTX0O014AqSNZsK4iJ9QyuxImZ5lYqivkmrvgvgsdxuSe4avs5Ldd2446giqfEz",
      appProfileCode: profile.toUpperCase(),
      visualName: profile.toUpperCase(),
      base_url: `https://${profile}.dev.corp.getfliff.com/`,
    };
  },
);

const ALL_X_PROD_PROFILES = [
  APP_PROFILE_PROD,
  APP_PROFILE_STAGING,
  APP_PROFILE_DEV,
  APP_PROFILE_PROD_STAGING_SOCIAL,
  ...DW_PROFILES,
] as const;

export class AppProfileUtils {
  private static _currentAppProfile: TAppProfile;
  public static get coreServerBaseUrl() {
    return AppProfileUtils._currentAppProfile.base_url;
  }

  public static get injectedDelayToAllServers() {
    return AppProfileUtils._currentAppProfile.inject_network_delay_in_millis;
  }

  public static get socialServerBaseUrl() {
    if (AppProfileUtils._currentAppProfile.social_server_base_url !== "") {
      return AppProfileUtils._currentAppProfile.social_server_base_url;
    }

    return AppProfileUtils._currentAppProfile.base_url;
  }

  public static get coreServerVisualName() {
    return AppProfileUtils._currentAppProfile.visualName;
  }

  public static get coreServerAxiosCode() {
    return AppProfileUtils._currentAppProfile.appProfileCode;
  }

  public static get coreServerClientId() {
    return AppProfileUtils._currentAppProfile.client_id;
  }

  public static get coreServerClientSecret() {
    return AppProfileUtils._currentAppProfile.client_secret;
  }

  public static get isSocialNasty() {
    return AppProfileUtils._currentAppProfile.inject_social_nasty;
  }
  public static initializeCurrentAppProfile() {
    if (!AppProfileUtils._currentAppProfile) {
      AppProfileUtils._currentAppProfile = DevConstants.isDevMode
        ? ALL_X_PROD_PROFILES.find(
            ({ appProfileCode }) =>
              appProfileCode === PersistentStorage.loadAuthTokens()[0],
          ) || AppProfileUtils.getAllAppProfiles()[0]
        : AppProfileUtils.getAllAppProfiles()[0];
    }
  }

  public static selectCurrentAppProfile(appProfileCode: string) {
    AppProfileUtils._currentAppProfile =
      AppProfileUtils.getAppProfileState(appProfileCode);
    CoreApiUrlManager.setLastKnownUrls(
      AppProfileUtils._currentAppProfile.base_url,
      AppProfileUtils._currentAppProfile.base_url,
      "",
      "profileChange",
    );
  }

  public static getAllAppProfiles() {
    return ALL_X_PROD_PROFILES.concat(AppProfileUtils.getSavedAppProfiles());
  }

  public static getSavedAppProfiles(): TAppProfile[] {
    const resp = PersistentStorage.getSavedAppProfiles();
    if (resp) {
      const data = JSON.parse(resp);
      return data || [];
    }
    return [];
  }

  public static addNewAppProfile(
    isProd: boolean,
    appProfile: Partial<TAppProfile>,
  ) {
    const newAppProfile = {
      ...(isProd ? APP_PROFILE_PROD : APP_PROFILE_DEV),
      ...appProfile,
    };

    const savedAppProfiles = AppProfileUtils.getSavedAppProfiles();
    savedAppProfiles.push(newAppProfile);
    PersistentStorage.setSavedAppProfiles(JSON.stringify(savedAppProfiles));

    return newAppProfile;
  }

  public static getAppProfileState(appProfileCode: string): TAppProfile {
    if (!DevConstants.isDevMode) {
      return APP_PROFILE_PROD;
    }

    const defs = AppProfileUtils.getAllAppProfiles().filter(
      def => def.appProfileCode === appProfileCode,
    );

    return defs.length > 0 ? defs[0] : APP_PROFILE_PROD;
  }

  public static coreServerInterceptAxiosRequestSuccess(
    config: ILocalAxiosRequestConfig,
  ) {
    config.inject_network_delay_in_millis = 0;
    config.inject_network_post_response_error = false;
    config.inject_network_error = false;
    config.inject_error_404 = false;
    config.damage_home_team_name = false;

    let injectedNastyMode = 0;
    const profile = AppProfileUtils._currentAppProfile;

    if (
      profile.inject_x_errors &&
      config.url &&
      config.url.indexOf("channels/all/games/") !== -1
    ) {
      injectedNastyMode = AppUtils.randomInt(1, 12);
      if (injectedNastyMode > 7) {
        injectedNastyMode = 0;
      }
    }

    if (injectedNastyMode === 1) {
      // * error 404
      config.url = config.url + "/asdasdas";
    }

    if (injectedNastyMode === 2) {
      if (config.headers) {
        // * bad access token
        config.headers.Authorization = config.headers.Authorization + "asf";
      }
    }

    if (injectedNastyMode === 3 && config.baseURL) {
      // * DNS error
      config.baseURL = config.baseURL.replace("https://", "https://asdsada");
    }

    if (injectedNastyMode === 4 && config.baseURL) {
      // * network timeout error
      config.baseURL = config.baseURL.replace(
        ".amazonaws.com",
        ".amazonaws.com:91",
      );
    }

    if (injectedNastyMode === 5) {
      config.damage_home_team_name = true;
    }

    if (injectedNastyMode === 6) {
      config.method = "delete";
    }

    if (injectedNastyMode === 7) {
      config.method = "post";
      config.url = "/api/v1/perfectparlay/bets/";
      config.data = "{ game: 303, prediction: 2 }";
    }

    if (profile.inject_nasty) {
      config.inject_network_delay_in_millis = AppUtils.randomInt(500, 900);
      config.inject_network_post_response_error =
        AppUtils.randomInt(0, 100) > 50;
    }
    if (profile.inject_network_delays_0) {
      config.inject_network_delay_in_millis = AppUtils.randomInt(500, 1000);
    } else if (profile.inject_network_delays_1) {
      config.inject_network_delay_in_millis = AppUtils.randomInt(3000, 5000);
    } else if (profile.inject_network_delays_2) {
      config.inject_network_delay_in_millis = AppUtils.randomInt(10000, 15000);
    } else if (profile.inject_network_delay_in_millis > 0) {
      config.inject_network_delay_in_millis =
        profile.inject_network_delay_in_millis;
    }

    if (profile.inject_random_network_errors_1) {
      config.inject_network_error = AppUtils.randomInt(0, 5) > 3;
    }
    if (
      config.method === "get" &&
      config.url &&
      config.url.indexOf("perfectparlay/bets/") !== -1
    ) {
      config.inject_network_error = true;
    }
    if (
      config.method === "get" &&
      config.url &&
      config.url.indexOf("users/profile/") !== -1
    ) {
      config.inject_network_error = true;
    }

    if (
      config.inject_network_error &&
      config.inject_network_delay_in_millis === 0
    ) {
      config.inject_network_delay_in_millis = 1;
    }
  }

  public static coreServerInterceptAxiosResponseSuccess(res: AxiosResponse) {
    const config = res.config as ILocalAxiosRequestConfig;

    if (config.inject_network_post_response_error) {
      throw new FliffException(
        FliffException.ERROR_1009__DUMMY_NETWORK_ERROR,
        "dummy network error on response",
      );
    }
    if (
      config.method &&
      config.method === "post" &&
      config.url &&
      config.url.indexOf("perfectparlay/bets") !== -1
    ) {
      throw new FliffException(
        FliffException.ERROR_1009__DUMMY_NETWORK_ERROR,
        "dummy network error on response",
      );
    }
    if (
      config.method &&
      config.method === "get" &&
      config.url &&
      config.url.indexOf("channels/all/games") !== -1
    ) {
      throw new FliffException(
        FliffException.ERROR_1009__DUMMY_NETWORK_ERROR,
        "dummy network error on response",
      );
    }

    if (config.damage_home_team_name) {
      if (res.data && res.data.response && res.data.response[0]) {
        res.data.response[0].home_team_name = "";
      }
    }
  }
}
