import { IBaseTabProps } from "components/atoms/common/TabFilter/atoms/Tab/types";
import { TOptionsSelectData } from "components/atoms_new/shared/Inputs/atoms/Select";
import { USA_STATES } from "constants/USA_STATES";
import { uniqBy } from "lodash";
import {
  BundleOfferSkin,
  PlayThroughPodCredibleState,
  PlayThroughPodPlayableState,
  PlayThroughPodProgressState,
  TransactionType,
} from "server/core/data/constants";
import {
  IFCMGetTransactionsReportResponse,
  IFCMTransaction,
  IPlayThroughPod,
  TFSMContactUsSubjectLine,
} from "server/core/data/objects";
import {
  Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def,
  Data__SB_Pick_Selection,
  Data__SB_SportEvent,
  IArenaDef,
  IDataSBConlictMarketClassDef,
  IPlayThroughBundle,
} from "server/legacyCore/data/objects";
import { IDataSBUnfinishedOrder } from "server/common/data/objects";
import {
  IFSMBadge,
  IFSMDataUserPickSelectionInfo,
} from "server/social/data/objects";
import {
  IStringMap,
  IUnfinishedTransaction,
  TAnyAlias,
  TPodState,
  TUSAStatesValues,
} from "src/types";
import { IValidationReturnType } from "./FormValidationUtils";
import { TimeUtils } from "./TimeUtils";
import { CashAmountFormatters } from "utils/UIAmountsFormatters";
import PodCompletedImage from "assets/img/playThrough/podCompleted.png";
import PodFailedImage from "assets/img/playThrough/podFailed.png";
import colors from "assets/styles/design-tokens/colors.module.scss";
import {
  PickType,
  PlacePickPlayThroughMode,
} from "server/legacyCore/data/constants";
import Constants from "constants/Constants";
import { Data__SB_ShoppingCartProposalItem } from "server/legacyCore/data/objectsLocal";

export class AppUtils {
  public static get getDeviceWidth() {
    return window.innerWidth;
  }

  public static sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public static dump(obj: unknown) {
    try {
      return JSON.stringify(obj);
    } catch (error) {
      return "dump_error: " + obj;
    }
  }

  public static randomString(n: number) {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < n; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  public static randomInt(min: number, max: number): number {
    if (isNaN(min) || isNaN(max)) {
      return 0;
    }
    const rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }

  public static nullToEmpty(text: string | null): string {
    if (text === null) {
      return "";
    }

    return text;
  }

  public static anythingToPositiveNumber(avalue: unknown): number {
    // convert anything to number (or NaN)
    let value = Number(avalue);

    if (isNaN(value)) {
      value = 0;
    }

    if (Math.floor(value) !== value) {
      value = 0;
    }

    if (value <= 0) {
      value = 0;
    }

    return value;
  }

  // 2020-04-15 / Ivan / lets try to ensure that redux state is immutable
  // code snippet stolen from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
  public static xDeepFreeze<T>(obj: T, level: number): T {
    const propNames = Object.getOwnPropertyNames(obj) as unknown as (keyof T)[];
    for (const name of propNames) {
      const value = obj[name];

      if (value && typeof value === "object") {
        AppUtils.xDeepFreeze(value, level + 1);
      }
    }

    return Object.freeze(obj);
  }

  public static zDeepFreeze<T>(obj: T): T {
    return AppUtils.xDeepFreeze(obj, 0);
  }

  public static convertStringArrayToSelectArray<T = string>(
    stringArr: Readonly<T[]>,
  ): TOptionsSelectData<T> {
    return stringArr.map(stringValue => ({
      value: stringValue,
      label: `${stringValue}`,
    }));
  }

  public static getConvertedToSelectedUSAStates(): TOptionsSelectData<TUSAStatesValues> {
    return AppUtils.convertStringArrayToSelectArray(USA_STATES);
  }

  public static pluraliseS(number: number, word: string): string {
    if (number === 1) {
      return word;
    }
    return `${word}s`;
  }

  public static getNumberSuffix(number: number) {
    const j = number % 10;
    const k = number % 100;

    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }

  public static getLeaderboardNumber(number: number) {
    if (number < 10) {
      return `0${number}`;
    }
    return `${number}`;
  }

  public static renameProp = <T>(
    oldKey: string,
    newKey: string,
    object: IStringMap<T>,
  ) => {
    const response: IStringMap<T> = {};
    for (const key in object) {
      if (key === oldKey) {
        response[newKey] = object[key];
      } else {
        response[key] = object[key];
      }
    }

    return response;
  };

  public static mapTransactionsDataToVisualList(
    transactionReport: Pick<
      IFCMGetTransactionsReportResponse,
      "unfinished_orders" | "transactions"
    >,
  ) {
    if (
      transactionReport.unfinished_orders &&
      transactionReport.unfinished_orders.length > 0
    ) {
      const copy = [
        ...transactionReport.transactions,
        ...transactionReport.unfinished_orders.map(
          AppUtils._purchaseToUnfinishedTransaction,
        ),
      ];
      copy.sort((a, b) => b.created_timestamp_utc - a.created_timestamp_utc);

      return copy;
    }
    const copy = [...transactionReport.transactions];
    copy.sort((a, b) => b.created_timestamp_utc - a.created_timestamp_utc);

    return copy;
  }

  public static getEventByConflictKey = (
    events: Data__SB_SportEvent[],
    conflictKey: string | undefined,
  ): Data__SB_SportEvent | null => {
    if (!conflictKey) {
      return null;
    }
    const event = events.find(
      ({ conflict_fkey: eventConflictKey }) => eventConflictKey === conflictKey,
    );
    if (event) {
      return event;
    }
    return null;
  };

  public static getEventByConflictKeyPrefix = (
    events: Data__SB_SportEvent[],
    conflictFKey: string,
  ): Data__SB_SportEvent | null => {
    const event = events.find(
      ({ conflict_fkey: eventConflictKey }) =>
        AppUtils.getEventFKeyPrefix(eventConflictKey) ===
        AppUtils.getEventFKeyPrefix(conflictFKey),
    );
    if (event) {
      return event;
    }
    return null;
  };

  public static extractFliffCashLimit = (
    limits:
      | Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def[]
      | undefined,
    maxRiskLimitCode: number | undefined,
  ) => {
    if (!limits) {
      return null;
    }
    const response = limits.find(
      limit => limit.limit_code === maxRiskLimitCode,
    );
    if (!response) {
      return null;
    }
    return response;
  };

  public static removeSpaces = (input: string): string =>
    input.replace(/\s/g, "");

  public static maskPhoneNumber = (phoneNumber: string): string =>
    `XXX-XXX-${phoneNumber.slice(-4)}`;

  public static isCancelButtonVisibleFactory = (
    transaction: IFCMTransaction,
    transactionsList: IFCMTransaction[],
  ): boolean => {
    const firstPendingInListIndex = transactionsList.findIndex(({ type }) =>
      Constants.cancelableTransactionTypes.includes(type),
    );
    const firstSuccessInListIndex = transactionsList.findIndex(({ type }) =>
      Constants.processedTransactionTypes.includes(type),
    );

    const isSuccessAbovePending =
      firstSuccessInListIndex !== -1 &&
      firstSuccessInListIndex < firstPendingInListIndex;
    const isFirstPendingInList =
      transactionsList[firstPendingInListIndex]?.id === transaction.id;

    return !isSuccessAbovePending && isFirstPendingInList;
  };

  public static isBundlePriceDoubled = (bundle: IPlayThroughBundle): boolean =>
    [
      BundleOfferSkin.CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1,
      BundleOfferSkin.CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2,
      BundleOfferSkin.CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3,
      BundleOfferSkin.CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4,
      BundleOfferSkin.CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5,
      BundleOfferSkin.CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6,
      BundleOfferSkin.CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1,
      BundleOfferSkin.CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2,
      BundleOfferSkin.CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3,
      BundleOfferSkin.CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4,
      BundleOfferSkin.CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5,
      BundleOfferSkin.CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6,
    ].includes(bundle.level_1_skin);

  public static mapArenasToTabFilter = (arenas: IArenaDef[]): IBaseTabProps[] =>
    arenas.map(
      ({
        arena_code: value,
        arena_name: label,
        arena_icon_url: iconUrl,
        arena_icon_v2_url: iconUrlV2,
      }) => ({
        value,
        label,
        iconURL: AppUtils._toValidArenaIcon(iconUrl, iconUrlV2),
      }),
    );

  public static toWonBadges = (badges: IFSMBadge[]): IFSMBadge[] => {
    const filtered = badges.filter(badge => !!badge.created_timestamp_utc);
    filtered.sort((a, b) => a.weight - b.weight);

    return filtered;
  };

  public static toLockedBadges = (badges: IFSMBadge[]): IFSMBadge[] => {
    const filtered = uniqBy(
      badges.filter(badge => badge.created_timestamp_utc === null),
      badge => badge.arena_code,
    );
    filtered.sort((a, b) => a.weight - b.weight);

    return filtered;
  };

  public static getDigitsLength(number: number) {
    return number.toString().length;
  }

  public static isNullable(value: TAnyAlias): value is null {
    return value === null || typeof value === "undefined";
  }

  public static getPodId(podFkey: string): string {
    if (podFkey.startsWith("fobj")) {
      return podFkey.replace("fobj__sb_fc_play_through_pod__", "");
    }
    return podFkey.replace(
      "system__redeemable_fc_pod_for__fobj__sb_user_profile__",
      "",
    );
  }

  public static getSubjectLineByIndex = (
    subjectLines: TFSMContactUsSubjectLine[],
    validationStates: IValidationReturnType[],
    index: number,
  ) => {
    return subjectLines.find(el => el.name === validationStates[index]?.value);
  };

  public static getSubjectsValues = (
    subjectLines: TFSMContactUsSubjectLine[],
    validationStates: IValidationReturnType[],
    index: number,
    acc: string[] = [],
  ): string[] => {
    acc.push(validationStates[index]?.value);

    const subject = AppUtils.getSubjectLineByIndex(
      subjectLines,
      validationStates,
      index,
    );

    if (subject?.type === "select") {
      return AppUtils.getSubjectsValues(
        subject.subject_lines,
        validationStates,
        index + 1,
        acc,
      );
    }
    if (subject?.type === "pickId") {
      acc.push(`PickID: ${validationStates[index + 1]?.value}`);
    }
    return acc;
  };

  public static isPlayableTotalBalanceRestricted(
    mode: PlacePickPlayThroughMode,
  ): boolean {
    return (
      mode === PlacePickPlayThroughMode.CONST_4502_CAN_PLACE_PICK_ONLY_PLAYABLE
    );
  }

  public static isRestrictionVisibleOnPlayableBalance(
    mode: PlacePickPlayThroughMode,
  ): boolean {
    return (
      mode ===
      PlacePickPlayThroughMode.CONST_4503_CAN_PLACE_PICK_ONLY_PLAYABLE_SHOW_RESTRICTION
    );
  }

  public static isFliffCashBundle(
    bundle: IPlayThroughBundle | null,
  ): bundle is IPlayThroughBundle {
    if (!bundle) {
      return false;
    }
    return (
      "level_2_data" in bundle &&
      bundle.amount_fliff_cash > 0 &&
      !!bundle.level_2_data
    );
  }

  private static _purchaseToUnfinishedTransaction = (
    order: IDataSBUnfinishedOrder,
  ): IUnfinishedTransaction => ({
    id: order.id,
    type: TransactionType.CONST_4070_SLIDE_UNFINISHED_TRANSACTION,
    created_timestamp_utc: TimeUtils.fromSecondsToMills(
      order.created_stamp_sec_utc,
    ),
    state: order.state_category,
    brand: order.brand,
    statusCode: order.state_code,
  });

  public static getEventFKeyPrefix = (eventFKey: string): string => {
    const separatorIndex = eventFKey.indexOf("_");
    return separatorIndex > -1 ? eventFKey.slice(0, separatorIndex) : eventFKey;
  };

  private static _toValidArenaIcon = (
    legacyUrl: string,
    newUrl: string,
  ): string => {
    if (newUrl) {
      if (newUrl.startsWith("https://")) {
        return newUrl;
      }
      return newUrl.replace("base64png://", "data:image/png;base64,");
    }

    return legacyUrl;
  };

  public static getPodProgressInPercents(pod: IPlayThroughPod): number {
    const progressInPercents =
      (pod.r_7003_play_through_progress / pod.r_7002_play_through_target) * 100;
    const int = Math.trunc(progressInPercents);

    if (isNaN(int) || !isFinite(int)) {
      return 0;
    }

    return Math.min(int, 100);
  }

  public static getPodMultiplier(podMultiplier: number): number {
    return podMultiplier / 100;
  }

  public static isBundleBonusSelectAvailable(
    playThroughBundle: IPlayThroughBundle | null | undefined,
  ): boolean {
    if (!playThroughBundle) {
      return false;
    }

    if (!playThroughBundle.level_2_data) {
      return false;
    }

    return playThroughBundle.level_2_data.length > 1;
  }

  public static getPodItemConfig(pod: IPlayThroughPod) {
    const state = this._getPodState(pod);

    const requirements = {
      title: "Play-Through Requirement",
      value: `${AppUtils.getPodMultiplier(
        pod.r_7022_initial_multiplier_in_percents,
      )}x`,
    };
    const promoOffer = {
      title: "Promo Offer",
      value: `icon ${CashAmountFormatters.toFliffCash(
        pod.r_7001_initial_fliff_cash,
      )}`,
    };
    const available = {
      title: "Available",
      value: `icon ${CashAmountFormatters.toFliffCash(
        pod.r_5011_v5_playable_fliff_cash,
      )}`,
    };
    const inPicks = {
      title: "In Picks",
      value: `icon ${CashAmountFormatters.toFliffCash(
        pod.r_5012_v5_locked_in_picks_fliff_cash,
      )}`,
    };
    const progress = {
      title: "Progress",
      value: `icon ${CashAmountFormatters.toFliffCash(
        pod.r_7003_play_through_progress,
      )} out of icon ${CashAmountFormatters.toFliffCash(
        pod.r_7002_play_through_target,
      )}`,
    };

    const progressInPercents = AppUtils.getPodProgressInPercents(pod);

    if (state === 0) {
      return {
        columns: [[requirements], [promoOffer], []],
        progressLabel: "Pods Suspended or Expired",
        ImageSource: PodFailedImage,
        progressInPercents: 0,
        progressBarColor: colors.primaryColor,
        footerLabel: "infoIcon Contact support for details !",
      } as const;
    }

    const transferredFooterLabel = `Transferred to your Redeemable Balance: fcIcon ${CashAmountFormatters.toFliffCash(
      pod.r_5014_v5_transferred_fliff_cash,
    )}`;

    if (state === 1) {
      return {
        columns: [[requirements], [promoOffer], [available]],
        progressLabel: "Play-Through Completed",
        ImageSource: PodFailedImage,
        progressInPercents: 100,
        progressBarColor: colors.darkTertiary,
        footerLabel: transferredFooterLabel,
      } as const;
    }

    if (state === 2) {
      return {
        columns: [[requirements], [promoOffer], [inPicks]],
        progressLabel: "Play-Through Completed",
        ImageSource: PodCompletedImage,
        progressInPercents: 100,
        progressBarColor: colors.darkTertiary,
        footerLabel: transferredFooterLabel,
      } as const;
    }

    if (state === 3) {
      return {
        columns: [[available, requirements], [inPicks], [promoOffer]],
        isExpandable: true,
        progressLabel: "Play-Through Completed",
        ImageSource: PodCompletedImage,
        progressInPercents: 100,
        progressBarColor: colors.darkTertiary,
        footerLabel: transferredFooterLabel,
      } as const;
    }

    if (state === 4) {
      return {
        columns: [[requirements], [promoOffer], []],
        progressLabel: "Play-Through Completed",
        ImageSource: PodCompletedImage,
        progressInPercents: 100,
        progressBarColor: colors.darkTertiary,
        footerLabel: transferredFooterLabel,
      } as const;
    }

    if (state === 5) {
      return {
        columns: [[requirements], [promoOffer], []],
        progressLabel: `${progressInPercents}%`,
        ImageSource: PodFailedImage,
        progressInPercents,
        progressBarColor: colors.primaryColor,
        footerLabel: transferredFooterLabel,
      } as const;
    }

    return {
      columns: [[progress, requirements], [available, promoOffer], [inPicks]],
      isExpandable: true,
      progressLabel: `${progressInPercents}%`,
      ImageSource: PodFailedImage,
      progressInPercents,
      progressBarColor: colors.tertiaryColor,
    } as const;
  }

  private static _getPodState(pod: IPlayThroughPod): TPodState {
    if (
      pod.credible_state === PlayThroughPodCredibleState.CONST_43209_REVERSED
    ) {
      return 0;
    }

    if (
      pod.progress_state ===
      PlayThroughPodProgressState.CONST_43409_TARGET_REACHED
    ) {
      if (
        pod.r_5011_v5_playable_fliff_cash !== 0 &&
        pod.r_5012_v5_locked_in_picks_fliff_cash !== 0
      ) {
        return 3;
      }

      if (pod.r_5011_v5_playable_fliff_cash !== 0) {
        return 1;
      }

      if (pod.r_5012_v5_locked_in_picks_fliff_cash !== 0) {
        return 2;
      }

      return 4;
    }

    if (pod.playable_state === PlayThroughPodPlayableState.CONST_43308_CLOSED) {
      return 5;
    }

    return 6;
  }

  public static toValidProductSku(sku: string): string {
    return sku.replace("vendor_sku:", "");
  }

  public static sortMarketTabs(
    data: IDataSBConlictMarketClassDef[],
    direction: "asc" | "desc",
  ): IDataSBConlictMarketClassDef[] {
    const copy = [...data];
    copy.sort((a, b) =>
      direction === "asc" ? a.tab_pos - b.tab_pos : b.tab_pos - a.tab_pos,
    );

    return copy;
  }

  public static sortEvents(
    events: Data__SB_SportEvent[],
  ): Data__SB_SportEvent[] {
    const eventsCopy = [...events];
    eventsCopy.sort((a, b) => {
      const firstStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
        a.event_start_timestamp_utc,
      );
      const secondsStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
        b.event_start_timestamp_utc,
      );
      if (a.weight === b.weight) {
        if (firstStampInMinutes === secondsStampInMinutes) {
          return (a.awayTeamName + a.homeTeamName).toLowerCase() >
            (b.awayTeamName + b.homeTeamName).toLowerCase()
            ? 1
            : -1;
        }
        return firstStampInMinutes - secondsStampInMinutes;
      }
      return a.weight - b.weight;
    });

    return eventsCopy;
  }

  public static sortProposalEntriesByConflictFkey<
    Item extends
      | Data__SB_ShoppingCartProposalItem
      | Data__SB_Pick_Selection
      | IFSMDataUserPickSelectionInfo,
  >(pickType: PickType, cartItemsValues: Item[]): Item[] {
    return pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS
      ? [...cartItemsValues].sort((a, b) =>
          ("event" in a ? a.event.conflict_fkey : a.conflict_fkey) <
          ("event" in b ? b.event.conflict_fkey : b.conflict_fkey)
            ? 1
            : -1,
        )
      : cartItemsValues;
  }
}
