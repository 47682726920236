import dayjs from "dayjs";

import { TimeUtils } from "utils/TimeUtils";

export default class DateFormatters {
  public static formatDateAtPrefixOnly(utcStamp: number): string {
    const formatter = this._getBaseFormatter(utcStamp);
    return TimeUtils.now(utcStamp).format(formatter);
  }

  public static formatCalendarSelectedDate(input: dayjs.ConfigType): string {
    const output = input ? TimeUtils.now(input) : TimeUtils.now();
    return output.format("MMM D, YYYY");
  }

  public static formatFullMonthYear(input: dayjs.ConfigType): string {
    return TimeUtils.now(input).format("MMMM YYYY");
  }

  public static formatTimeAtSuffixOnly(utcStamp: dayjs.ConfigType): string {
    return TimeUtils.now(utcStamp).format("h:mm a");
  }

  public static formatDateTime(utcStamp: number): string {
    const formatter = `${this._getBaseFormatter(utcStamp)}h:mm a`;
    return TimeUtils.now(utcStamp).format(formatter);
  }

  public static formatTimeFull = (inputDate: dayjs.ConfigType): string =>
    TimeUtils.now(inputDate).format("dddd[,] MMMM D[,] YYYY");

  public static formatReportVisualDate(dateString: dayjs.ConfigType): string {
    return TimeUtils.now(dateString).format("MMM D[,] YYYY");
  }

  public static formatRegularVisualDate(dateString: dayjs.ConfigType): string {
    return TimeUtils.now(dateString, "YYYY-MM-DD", true).format("YYYY-MM-DD");
  }

  public static formatLeaderboardPeriod(start: string, end: string): string {
    const startDate = TimeUtils.now(start);
    const endDate = TimeUtils.now(end);

    return `${startDate.format("MMM D[ - ]")}${endDate.format("D[, ] YYYY")}`;
  }
  public static formatTimeFromNow(utcStamp: number) {
    const now = TimeUtils.now();
    const end = TimeUtils.now(utcStamp);
    const duration = dayjs.duration(end.diff(now));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    return [days && `${days}d`, hours && `${hours}h`, minutes && `${minutes}m`]
      .filter(Boolean)
      .join(" ");
  }

  private static _getBaseFormatter(utcStamp: number) {
    const dayOffsetFromToday = TimeUtils.dayOffsetFromToday(utcStamp);
    let formatter = "MMM D[,] YYYY [at] ";

    if (dayOffsetFromToday === 0) {
      formatter = "[Today at] ";
    }
    if (dayOffsetFromToday === 1) {
      formatter = "[Tomorrow at] ";
    }

    return formatter;
  }
}
