import "./styles.scss";
import { IProps } from "./types";

import ExclamationMark from "components/atoms/common/ExclamationMark";
import CustomTooltip from "components/atoms/mobile/CustomTooltip";

const Tooltip = ({ message, placement = "left" }: IProps) => {
  return (
    <div className="input-tooltip-container">
      <CustomTooltip content={message} position={placement}>
        <ExclamationMark />
      </CustomTooltip>
    </div>
  );
};

export default Tooltip;
