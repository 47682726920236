import dayjs from "dayjs";
import { useState } from "react";
import { TAnyAlias } from "src/types";

import { TimeUtils } from "utils/TimeUtils";

export type TUseTimer = [
  string,
  (incomeDate: dayjs.ConfigType) => void,
  () => void,
  boolean,
];

const useTimer = (format = "HH:mm:ss"): TUseTimer => {
  let timerID = -1;
  const [timeFormat, setTimeFormat] = useState<string>("");
  const [isTicking, setTicking] = useState<boolean>(false);

  const updateTimeFormat = (incomeDate: dayjs.ConfigType): void => {
    let distance = getDistance(incomeDate);
    if (distance < 0) {
      // 2020-05-05 / Ivan / we dont want to render 0-1:0-1:0-1
      distance = 0;
    }
    const timeCountdown = getTimeFormat(distance);
    setTimeFormat(timeCountdown);
  };

  const getTimeFormat = (distance: number): string =>
    dayjs.duration(distance, "milliseconds").format(format);

  const getDistance = (end: dayjs.ConfigType): number => {
    const now = TimeUtils.now();
    const countDownDate = TimeUtils.now(end).toDate().getTime();
    // Distance between now and the count-down date
    return countDownDate - now.toDate().getTime();
  };

  const startTimer = (incomeDate: dayjs.ConfigType): void => {
    if (
      TimeUtils.now(incomeDate).toDate().getTime() <=
      TimeUtils.now().toDate().getTime()
    ) {
      stopTimer();
      return;
    }
    updateTimeFormat(incomeDate);

    setTicking(true);
    startInterval(incomeDate);
  };

  const startInterval = (dateToStart: dayjs.ConfigType): void => {
    // To clear prev interval in multiple times calling
    stopTimer();
    timerID = <TAnyAlias>setInterval(() => {
      const distance = getDistance(dateToStart);

      updateTimeFormat(dateToStart);

      // Clear timer
      if (distance <= 0) {
        stopTimer();
      }
    }, 1000);
  };

  const stopTimer = (): void => {
    if (timerID < 0) {
      // Ivan / not running, no need to do anything
      return;
    }
    try {
      clearInterval(timerID);
      timerID = -1;
      setTicking(false);
    } catch (err) {
      console.warn("error in stop timer", err);
    }
  };

  return [timeFormat, startTimer, stopTimer, isTicking];
};

export default useTimer;
