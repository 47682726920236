import SplashBackground from "assets/img/SplashBackground.png";

import "./styles.scss";
import { useEffect } from "react";
import LoadingSpinner from "components/atoms_new/shared/LoadingSpinner";

const SplashScreen = () => {
  // Disable background scroll when splash screen is open
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  return (
    <div className="splash-screen">
      <img
        className="splash-screen__img"
        src={SplashBackground}
        alt="Fliff splash screen"
      />
      <div className="splash-screen__overlay" />
      <LoadingSpinner size={"medium"} className={"splash-screen__loader"} />
    </div>
  );
};

export default SplashScreen;
