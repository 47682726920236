import { AppProfileUtils } from "utils/AppProfileUtils";

class CoreApiUrlManager {
  private _nextPublicEndpoint = "";
  private _nextPrivateEndpoint = "";
  private _nextHeraldPublicEndpoint = "";
  private _nextFantasyPublicEndpoint = "https://m-c703.app.getfliff.com/";

  public get nextFantasyPublicEndpoint() {
    return this._nextFantasyPublicEndpoint;
  }

  public get defaultBaseUrl(): string {
    return AppProfileUtils.coreServerBaseUrl;
  }

  public get nextHeraldPublicEndpoint() {
    return this._nextHeraldPublicEndpoint;
  }

  public get nextPublicEndpoint() {
    return this._nextPublicEndpoint;
  }

  public get nextPrivateEndpoint() {
    return this._nextPrivateEndpoint;
  }

  public setLastKnownUrls(
    publicEndpoint: string,
    privateEndpoint: string,
    heraldPublicEndpoint: string,
    source: string,
  ): void {
    console.warn(
      `Urls' are set from ${source}: prev public: ${
        this._nextPublicEndpoint || "empty"
      }, next public: ${publicEndpoint || "empty"}, prev private: ${
        this._nextPrivateEndpoint || "empty"
      }, next  private: ${privateEndpoint || "empty"}, prev herald: ${
        this._nextHeraldPublicEndpoint || "empty"
      }, next herald: ${heraldPublicEndpoint || "empty"}`,
    );
    if (publicEndpoint !== "default") {
      this._nextPublicEndpoint = publicEndpoint;
    }
    if (privateEndpoint !== "default") {
      this._nextPrivateEndpoint = privateEndpoint;
    }
    this._nextHeraldPublicEndpoint = heraldPublicEndpoint;
  }

  public getBaseUrl = (
    prevBaseUrl: string | undefined,
    isPublic: boolean | undefined,
  ): string => {
    if (!prevBaseUrl) {
      console.warn("getBaseUrl => initialUrl is not defined.");
      return "";
    }
    if (typeof isPublic === "undefined") {
      console.warn("getBaseUrl => isPublic is not defined.");
      return prevBaseUrl;
    }

    if (isPublic) {
      if (this._nextPublicEndpoint.length > 0) {
        return this._nextPublicEndpoint;
      }
      return this._replaceDefaultPublicUrl(prevBaseUrl);
    }

    if (this._nextPrivateEndpoint.length > 0) {
      return this._nextPrivateEndpoint;
    }
    return this._replaceDefaultPrivateUrl(prevBaseUrl);
  };

  private _replaceDefaultPublicUrl(prevBaseUrl: string): string {
    let result = prevBaseUrl;
    result = result.replace("/app.getfliff.com", "/m-c2.app.getfliff.com");
    result = result.replace("/dev.getfliff.com", "/m-c2.dev.getfliff.com");

    return result;
  }

  private _replaceDefaultPrivateUrl(prevBaseUrl: string): string {
    let result = prevBaseUrl;
    result = result.replace("/app.getfliff.com", "/m-c1.app.getfliff.com");
    result = result.replace("/dev.getfliff.com", "/m-c1.dev.getfliff.com");

    return result;
  }
}

export default new CoreApiUrlManager();
