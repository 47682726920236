import {
  IFliffProtocolResponse,
  IFliffResponse,
} from "server/common/data/objects";
import { IInternalProtocolResponseProcessor } from "server/common/interfaces";
import { IFCMProtocolResponseSlots } from "server/core/data/objects";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { staticGetState } from "reduxLocal/store";
import {
  CoreInjectProtocolSlotsAction,
  CoreSetTicketProcessingStateAction,
} from "reduxLocal/core/core.actions";

export class InternalProtocolResponseProcessorImpl
  implements
    IInternalProtocolResponseProcessor<
      IFCMProtocolResponseSlots,
      IFliffResponse
    > {
  public processProtocolResponseSlots<Response extends IFliffResponse>(
    protocolResponse: IFliffProtocolResponse<
      IFCMProtocolResponseSlots,
      Response
    >,
  ): void {
    const slots = protocolResponse.x_slots;

    if (!slots) {
      return;
    }

    // Make sure to reset ticket state before slots are processing. See ProposalUtils.staleProposalsUpdates
    if (
      protocolResponse.result.error &&
      CoreSelectors.Miscellaneous.isTicketProcessing(staticGetState())
    ) {
      CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
        false,
      );
    }

    CoreInjectProtocolSlotsAction.dispatchInjectProtocolSlots(slots);
  }
}
