const markdownStringGroup = /(<[a-z]*>.[^<]*<\/[a-z]*>)/gi;
const markdownString = /<([a-z]*)>(.*)<\/[a-z]*>/i;

class MarkdownUtils {
  public static getSplittedText = (text: string) => {
    return text.split(markdownStringGroup);
  };

  public static getParsedText = (text: string) => {
    const parsedLink = text.match(markdownString);
    if (!parsedLink) {
      return null;
    }
    const [, tag, value] = parsedLink;
    return { tag, value };
  };

  public static getFilteredText = (splitted: string[]) => {
    return splitted.filter(el => markdownString.test(el));
  };
}

export default MarkdownUtils;
