import { createSelector } from "reselect";

import CoreSelectors from "reduxLocal/core/core.selectors";
import { TGlobalAppState } from "reduxLocal/rootReducer";
import { PickType, UIConstants } from "server/legacyCore/data/constants";
import { Data__SB_SportEvent } from "server/legacyCore/data/objects";
import {
  Data__SB_ShoppingCartProposalItem,
  IBetMarketLocal,
} from "server/legacyCore/data/objectsLocal";
import { IStringMap } from "src/types";
import PickUtils from "utils/PickUtils";
import ProposalUtils from "utils/ProposalUtils";
import {
  CashAmountFormatters,
  CoinsAmountFormatters,
} from "utils/UIAmountsFormatters";
import { AppUtils } from "utils/AppUtils";

import UIPickUtils from "utils/UIPickUtils";
import createDeepEqualSelector from "selectors/atoms/createDeepEqualSelector";
import ChannelUtils from "utils/ChannelUtils";
import { TimeUtils } from "utils/TimeUtils";

export const getShoppingCartUIS = (state: TGlobalAppState) =>
  state.sportsBook.shopping_cart_ui;

export const getBetSupermarket = (state: TGlobalAppState) =>
  state.sportsBook.bet_supermarket.shelves_by_conflict_fkey;

// Caution, use this in rare cases !!!
// Supermarket is very deep entity, such check could negative affect in performance.
export const getBetSupermarketDeepEqual = createDeepEqualSelector(
  getBetSupermarket,
  sup => sup,
);

export const getProposalsMap = (state: TGlobalAppState) =>
  state.sportsBook.proposals;

export const getShoppingCart = (state: TGlobalAppState) =>
  state.sportsBook.shopping_cart;

export const getCartItems = (state: TGlobalAppState) =>
  getShoppingCart(state).items;

export const makeMarketsMapByClassCode = (conflictKey: string | undefined) =>
  createSelector(getBetSupermarket, betSupermarket => {
    const markets = betSupermarket[conflictKey || ""]?.markets;

    if (!markets) {
      return {};
    }

    return markets.reduce<IStringMap<IBetMarketLocal[]>>((acc, curr) => {
      curr.market_class_codes.forEach(classCode => {
        if (!acc[classCode]) {
          acc[classCode] = [];
        }
        if (!ProposalUtils.isMarketAltProps(curr)) {
          acc[classCode].push(curr);
        }
      });
      return acc;
    }, {});
  });

export const getCartItemsCount = createSelector(
  getCartItems,
  cartItems => Object.keys(cartItems).length,
);

export const getTotalParlayOddsCount = createSelector(
  getShoppingCart,
  PickUtils.getParlayOddsCoeff,
);

export const getCartPickType = (state: TGlobalAppState) =>
  getShoppingCart(state).pickType;

export const makeIsCartItemsIncludesProposalFactory = (cartItemKey: string) =>
  createSelector(getCartItems, cartItems => !!cartItems[cartItemKey]);

export const getGoldCoinsBalance = (state: TGlobalAppState) =>
  getCustomerProfile(state).amount_v2_tokens;

export const getCurrencyMode = (state: TGlobalAppState) =>
  getShoppingCartUIS(state).ui_currency_mode;

export const getSportsBookConfig = (state: TGlobalAppState) =>
  state.sportsBook.config;

export const getStraightCartTotalCartPayout = createSelector(
  getShoppingCart,
  getCurrencyMode,
  (shoppingCart, currencyMode) => {
    const rawPayout = Object.values(shoppingCart.items).reduce(
      (acc, curr) =>
        acc +
        PickUtils.calcRawPayoutAmountImpl(curr, shoppingCart, currencyMode),
      0,
    );
    return PickUtils.calcRawAmount(rawPayout, 1);
  },
);

const getIsPlacedBettingEventsAvailable = createSelector(
  CoreSelectors.Events.rawEvents,
  getCartItems,
  (events, cartItems) =>
    PickUtils.isPlacedBettingEventsAvailable(cartItems, events),
);

export const getInviteFriendsDescriptions = createSelector(
  getSportsBookConfig,
  config => ({
    title: config.referral_bonus__text_1,
    disclaimer: config.referral_bonus__text_2,
  }),
);

export const getCustomerProfile = (state: TGlobalAppState) =>
  state.sportsBook.profile;

export const getReferralBonusReceivedAmountPerFriendPurchase = createSelector(
  getSportsBookConfig,
  config => config.referral_bonus__percent_from_child_purchase,
);

export const getReferralMaxBonusReceivedAmount = createSelector(
  getSportsBookConfig,
  config => config.xp_bonus_referral__max_amount_per_child,
);

export const getDisableIapUiUnfinishedPurchaseInProgress = createSelector(
  getCustomerProfile,
  profile => profile.open_iap_purchases_count > 0,
);

export const getAffiliateHash = createSelector(
  getCustomerProfile,
  profile => profile.sb_affiliate_hash,
);

export const getMayDisplayRateUsNag = (state: TGlobalAppState) =>
  getCustomerProfile(state).may_display_rate_us_nag;

export const getMayDisplayRateUsDebugReason = (state: TGlobalAppState) =>
  getCustomerProfile(state).may_display_rate_us_reason;

export const getLastV4XPointsBonus = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile.d_51207_last_v4_xpoints_bonus,
  lastV4XPointsBonus => lastV4XPointsBonus,
);

export const getLastV2CompletedDailyChallenge = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile.d_51206_last_v2_completed_daily_challenge,
  lastV2CompletedDailyChallenge => lastV2CompletedDailyChallenge,
);

export const getLastV3PurchaseErrorOrder = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile.d_51231_last_v3_purchase_error_order,
  lastV3PurchaseErrorOrder => lastV3PurchaseErrorOrder,
);

export const getLastV3PurchaseSuccessTransaction = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile
      .d_51232_last_v3_purchase_success_transaction,
  lastV3PurchaseSuccessTransaction => lastV3PurchaseSuccessTransaction,
);

export const getLastTransaction4116PendingFCCredit = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile
      .d_51251_last_transaction_4116_pending_fc_credit,
  lastTransaction4116PendingFCCredit => lastTransaction4116PendingFCCredit,
);

export const getLastTransaction4115mailinEntry = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile
      .d_51250_last_transaction_4115_mail_in_entry,
  lastTransaction4115mailinEntry => lastTransaction4115mailinEntry,
);
export const getLastTransaction4117ClearedFCCredit = createDeepEqualSelector(
  (state: TGlobalAppState) =>
    state.sportsBook.x_delta_profile
      .d_51252_last_transaction_4117_cleared_fc_credit,
  lastTransaction4117ClearedFCCredit => lastTransaction4117ClearedFCCredit,
);

export const getMaxAmountPointsForPlacePicks = (state: TGlobalAppState) =>
  getCustomerProfile(state).amount_max_v3_xpoints_for_place_pick;

export const getXPBalance = (state: TGlobalAppState) =>
  getCustomerProfile(state).amount_v3_xpoints;

export const getNextBonusXPAmount = (state: TGlobalAppState) =>
  getSportsBookConfig(state).xp_bonus_progress__amount_bonus;

export const getIsPurchaseProcessing = (state: TGlobalAppState) =>
  getCustomerProfile(state).open_orders_count !== 0;

export const makeConflictClassByCodeFactory = (classCode: number | undefined) =>
  createSelector(
    getSportsBookConfig,
    config =>
      config.conflict_classes.find(input => input.code === classCode) ?? null,
  );

export const getPendingPicksCount = createSelector(
  getCurrencyMode,
  getCustomerProfile,
  (currencyMode, profile) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return profile.d_51004_fliff_cash_active_picks_count;
    }
    return profile.d_51002_tokens_active_picks_count;
  },
);

export const getIsSurveyEnabled = (state: TGlobalAppState) =>
  getCustomerProfile(state).survey_title.length > 0;

export const getSurveyConfig = createSelector(getCustomerProfile, profile => ({
  title: profile.survey_title,
  description: profile.survey_description,
  uri: profile.survey_url,
  logoUrl: profile.survey_logo_url,
}));

export const getIdentityVerificationStatus = (state: TGlobalAppState) =>
  getCustomerProfile(state).identity_verification_status;

export const getProfilePersonalDetails = (state: TGlobalAppState) =>
  getCustomerProfile(state).personal_info;

export const getAmountRequiredForSecondLevelVerification = (
  state: TGlobalAppState,
) => getSportsBookConfig(state).amount_which_requires_level_2_verification;

export const getBalanceInSelectedCurrency = createSelector(
  getCurrencyMode,
  getCustomerProfile,
  CoreSelectors.Balances.playableTotalFliffCashBalance,
  (currencyMode, profile, playableTotalFliffCashBalance) => {
    const isModeCash = UIConstants.isModeCash(currencyMode);
    if (isModeCash) {
      return playableTotalFliffCashBalance;
    }
    return profile.amount_v2_tokens;
  },
);

export const getCartRawTotalByPickType = createSelector(
  getShoppingCart,
  getCurrencyMode,
  getCartPickType,
  (shoppingCart, currencyMode, pickType) =>
    PickUtils.calcRawAmount(
      PickUtils.calcRawTotalBetAmountImpl(shoppingCart, currencyMode, pickType),
      1,
    ),
);

export const getIsCartBalanceExceededByPickType = createSelector(
  getBalanceInSelectedCurrency,
  getCartRawTotalByPickType,
  (balanceInCurrency, rawCartTotal) => rawCartTotal > balanceInCurrency,
);

export const makeIsSingleSelectionInvalid = (
  selection: Data__SB_ShoppingCartProposalItem,
) =>
  createSelector(getCartItems, getCartPickType, (cartItems, pickType) =>
    PickUtils.isSingleSelectionInvalid(selection, cartItems, pickType),
  );

const makeIsEventBettingAvailable = (conflictFkey: string) =>
  createSelector(CoreSelectors.Events.rawEvents, events =>
    Boolean(AppUtils.getEventByConflictKey(events, conflictFkey)),
  );

export const makeIsSelectionUnavailableForBetting = (
  selection: Data__SB_ShoppingCartProposalItem,
) =>
  createSelector(
    makeIsEventBettingAvailable(selection.event.conflict_fkey),
    makeMainBetProposal(selection.proposal_fkey, selection.event.conflict_fkey),
    (isBettingEventAvailable, supermarketBetProposal) =>
      ProposalUtils.isSelectionUnavailable(
        selection,
        supermarketBetProposal,
        isBettingEventAvailable,
      ),
  );

export const isShoppingCartUnavailable = createSelector(
  getShoppingCart,
  CoreSelectors.Events.rawEvents,
  getProposalsMap,
  getBetSupermarket,
  (cartItems, events, proposalsMap, betSupermarket) => {
    return Object.values(cartItems.items).every(cartItem => {
      const isBettingEventAvailable = Boolean(
        AppUtils.getEventByConflictKey(events, cartItem.event.conflict_fkey),
      );
      const supermarketBetProposal = ProposalUtils.getBetProposalByProposalFkey(
        cartItem.proposal_fkey,
        cartItem.event.conflict_fkey,
        proposalsMap,
        betSupermarket,
      );

      return ProposalUtils.isSelectionUnavailable(
        cartItem,
        supermarketBetProposal,
        isBettingEventAvailable,
      );
    });
  },
);

export const makeMainBetProposal = (
  proposalFkey: string | undefined,
  conflictFkey: string | undefined,
) =>
  createSelector(
    getProposalsMap,
    getBetSupermarket,
    (proposalsMap, betSupermarket) =>
      ProposalUtils.getBetProposalByProposalFkey(
        proposalFkey,
        conflictFkey,
        proposalsMap,
        betSupermarket,
      ),
  );

export const isOptionSelectedFactory = (proposalKey: string, groupId: string) =>
  createSelector(getCartItems, cartItems => {
    if (!groupId || !cartItems[groupId] || !cartItems[groupId].proposal_fkey) {
      return false;
    }
    return cartItems[groupId].proposal_fkey === proposalKey;
  });

export const makeSelectionNameChangeConfig = (
  selection: Data__SB_ShoppingCartProposalItem,
) =>
  createDeepEqualSelector(
    makeIsSelectionUnavailableForBetting(selection),
    makeMainBetProposal(selection.proposal_fkey, selection.event.conflict_fkey),
    (isSelectionUnavailableForBetting, mainBetProposal) => {
      if (isSelectionUnavailableForBetting) {
        return {
          isChanged: true,
          selectionName: selection.t_141_selection_name,
        };
      }
      if (
        selection.t_141_selection_name !== mainBetProposal?.t_141_selection_name
      ) {
        return {
          isChanged: true,
          selectionName: mainBetProposal?.t_141_selection_name,
        };
      }
      if (selection.isReplaced) {
        return {
          isChanged: true,
          selectionName: selection.t_141_selection_name,
        };
      }

      return {
        isChanged: false,
        selectionName: selection.t_141_selection_name,
      };
    },
  );

export const makeSelectionCoeffConfig = (
  cartItem: Data__SB_ShoppingCartProposalItem,
) =>
  createSelector(
    makeIsSelectionUnavailableForBetting(cartItem),
    makeMainBetProposal(cartItem.proposal_fkey, cartItem.event.conflict_fkey),
    (isSelectionUnavailableForBetting, mainBetProposal) => {
      if (isSelectionUnavailableForBetting) {
        return { isChanged: true, coeff: "N/A" };
      }
      if (cartItem.coeff !== mainBetProposal?.coeff) {
        return {
          isChanged: true,
          coeff: UIPickUtils.getPickCoefficient(
            mainBetProposal?.coeff || 0,
            cartItem.t_142_selection_param_1,
          ),
        };
      }
      if (cartItem.isReplaced) {
        return {
          isChanged: true,
          coeff: UIPickUtils.getPickCoefficient(
            cartItem.coeff,
            cartItem.t_142_selection_param_1,
          ),
        };
      }

      return {
        isChanged: false,
        coeff: UIPickUtils.getPickCoefficient(
          cartItem.coeff,
          cartItem.t_142_selection_param_1,
        ),
      };
    },
  );

export const makeSelectionForReplacement = (
  cartItem: Data__SB_ShoppingCartProposalItem,
) =>
  createDeepEqualSelector(
    CoreSelectors.Events.rawEvents,
    getProposalsMap,
    getBetSupermarketDeepEqual,
    makeIsSelectionUnavailableForBetting(cartItem),
    (events, proposalsMap, betSupermarket, isSelectionUnavailable) => {
      if (isSelectionUnavailable) {
        return PickUtils.getCartItemForReplacement(
          betSupermarket,
          proposalsMap,
          cartItem,
          AppUtils.getEventByConflictKey(events, cartItem.event.conflict_fkey),
        );
      }
      return null;
    },
  );

export const getMaxPayoutByPickType = createSelector(
  getCartPickType,
  getSportsBookConfig,
  getCurrencyMode,
  (pickType, config, currencyMode) => {
    if (UIConstants.isModeCash(currencyMode)) {
      if (pickType === PickType.CONST_81_STRAIGHT) {
        return CashAmountFormatters.toFliffCash(
          config.fliff_cash_straight__max_payout_in_cents,
        );
      }
      if (
        pickType === PickType.CONST_82_PARLAY ||
        pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS
      ) {
        return CashAmountFormatters.toFliffCash(
          config.fliff_cash_parlay__max_payout_in_cents,
        );
      }
    } else {
      if (pickType === PickType.CONST_81_STRAIGHT) {
        return CoinsAmountFormatters.toGoldCoins(
          config.credits_straight__max_payout_in_cents,
        );
      }
      if (
        pickType === PickType.CONST_82_PARLAY ||
        pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS
      ) {
        return CoinsAmountFormatters.toGoldCoins(
          config.credits_parlay__max_payout_in_cents,
        );
      }
    }
    return "[unknown]";
  },
);

export const getIsParlayAllowedToSubmit = createSelector(
  getCartItems,
  PickUtils.isParlayAllowed,
);

export const getIsSGPAllowedToSubmit = createSelector(
  getCartItems,
  PickUtils.isSGPAllowed,
);

export const getIsSGPDisclaimerVisible = createSelector(
  getIsSGPAllowedToSubmit,
  getCartItemsCount,
  getCartPickType,
  (isSGPAllowedToSubmit, cartItemsCount, pickType) =>
    !isSGPAllowedToSubmit &&
    cartItemsCount > 1 &&
    pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS,
);

export const makeStraightSelectionRiskAmount = (key: string) =>
  createSelector(getCartItems, getCurrencyMode, (cartItems, currencyMode) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return cartItems[key].placed_amount__fliff_cash;
    }
    return cartItems[key].placed_amount__gold_coins;
  });

export const makeStraightSelectionPayoutAmount = (key: string) =>
  createSelector(
    getCartItems,
    makeStraightSelectionRiskAmount(key),
    (cartItems, selectionPlacedAmount) =>
      PickUtils.recalcRawAmountForCoeff(
        selectionPlacedAmount,
        PickUtils.usaCoeffToEuCoeff(cartItems[key].coeff),
      ),
  );

export const getParlayPickTotalRiskAmount = createSelector(
  getCurrencyMode,
  getShoppingCart,
  (currencyMode, shoppingCart) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return shoppingCart.totalPlacedFliffCashAmount;
    }
    return shoppingCart.totalPlacedGoldCoinsAmount;
  },
);

export const makeIsInputPayoutMaxAmountExceeded = (payoutAmount: number) =>
  createSelector(
    getSportsBookConfig,
    getCurrencyMode,
    getCartPickType,
    (config, currencyMode, pickType) => {
      if (UIConstants.isModeCash(currencyMode)) {
        if (pickType === PickType.CONST_81_STRAIGHT) {
          return payoutAmount > config.fliff_cash_straight__max_payout_in_cents;
        }
        return payoutAmount > config.fliff_cash_parlay__max_payout_in_cents;
      }
      if (pickType === PickType.CONST_81_STRAIGHT) {
        return payoutAmount > config.credits_straight__max_payout_in_cents;
      }
      return payoutAmount > config.credits_parlay__max_payout_in_cents;
    },
  );

export const makeSelectionFCLimitValueByKey = (
  selectionKey: string | undefined,
) =>
  createSelector(getCustomerProfile, getCartItems, (profile, cartItems) => {
    if (!selectionKey) {
      return null;
    }
    const selection = cartItems[selectionKey].max_risk_limit;

    const limit = AppUtils.extractFliffCashLimit(profile.fc_limits, selection);
    if (limit) {
      return limit.max_amount_in_cents;
    }
    return null;
  });

export const getGCMinPickAmountByPickType = createSelector(
  getSportsBookConfig,
  getCartPickType,
  (config, pickType) => {
    if (
      pickType === PickType.CONST_82_PARLAY ||
      pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS
    ) {
      return config.credits_parlay__min_pick_amount_in_cents;
    }
    return config.credits_straight__min_pick_amount_in_cents;
  },
);

export const getFCMinPickAmountByPickType = createSelector(
  getSportsBookConfig,
  getCartPickType,
  (config, pickType) => {
    if (
      pickType === PickType.CONST_82_PARLAY ||
      pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS
    ) {
      return config.fliff_cash_parlay__min_pick_amount_in_cents;
    }
    return config.fliff_cash_straight__min_pick_amount_in_cents;
  },
);

export const getIsStraightAnyPickPayoutExceeded = createSelector(
  getCartItems,
  getSportsBookConfig,
  getCurrencyMode,
  getCustomerProfile,
  (cartItems, config, currencyMode, profile) => {
    const values = Object.values(cartItems);
    if (UIConstants.isModeCash(currencyMode)) {
      return values.some(cartItem => {
        const isPayoutExceeded =
          PickUtils.recalcRawAmountForCoeff(
            cartItem.placed_amount__fliff_cash,
            PickUtils.usaCoeffToEuCoeff(cartItem.coeff),
          ) > config.fliff_cash_straight__max_payout_in_cents;
        const limit = AppUtils.extractFliffCashLimit(
          profile.fc_limits,
          cartItem.max_risk_limit,
        );
        const isLimitExceeded = limit
          ? cartItem.placed_amount__fliff_cash > limit.max_amount_in_cents
          : false;

        return isLimitExceeded || isPayoutExceeded;
      });
    }
    return values.some(
      cartItem =>
        PickUtils.recalcRawAmountForCoeff(
          cartItem.placed_amount__gold_coins,
          PickUtils.usaCoeffToEuCoeff(cartItem.coeff),
        ) > config.credits_straight__max_payout_in_cents,
    );
  },
);

const getShouldLoadRequestNewCoeff = (state: TGlobalAppState) =>
  getShoppingCartUIS(state).sgpStatusData.shouldRequestNewCoeff;

export const getSGPCoeff = (state: TGlobalAppState) =>
  getShoppingCartUIS(state).sgpStatusData.totalCoeff;

const getDidCoeffOnSubmitChange = (state: TGlobalAppState) =>
  getShoppingCartUIS(state).sgpStatusData.coeffOnSubmitDidChange;

export const getParlayTotalCoeff = createSelector(
  getCartPickType,
  getSGPCoeff,
  getTotalParlayOddsCount,
  (pickType, totalSGPCoeff, totalParlayCoeff) => {
    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return totalSGPCoeff;
    }
    return totalParlayCoeff;
  },
);

export const getParlayCartTotalPayout = createSelector(
  getCurrencyMode,
  getShoppingCart,
  getCartPickType,
  getSGPCoeff,
  getParlayPickTotalRiskAmount,
  getIsParlayAllowedToSubmit,
  (
    currencyMode,
    shoppingCart,
    pickType,
    sgpCoeff,
    parlayPickTotalRiskAmount,
    isParlayAllowedToSubmit,
  ) => {
    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      if (sgpCoeff === 0) {
        return 0;
      }
      return PickUtils.calcRawPayoutAmountForSGPPickType(
        parlayPickTotalRiskAmount,
        sgpCoeff,
      );
    }

    if (!isParlayAllowedToSubmit) {
      return 0;
    }
    return PickUtils.calcRawPayoutAmountForWholeCart(
      shoppingCart,
      currencyMode,
    );
  },
);

const getIsParlayPayoutAmountExceeded = createSelector(
  getParlayCartTotalPayout,
  getSportsBookConfig,
  getCurrencyMode,
  (payoutAmount, config, currencyMode) => {
    if (UIConstants.isModeCash(currencyMode)) {
      return payoutAmount > config.fliff_cash_parlay__max_payout_in_cents;
    }
    return payoutAmount > config.credits_parlay__max_payout_in_cents;
  },
);

export const getIsCartPaxPayoutExceeded = createSelector(
  getCartPickType,
  getIsStraightAnyPickPayoutExceeded,
  getIsParlayPayoutAmountExceeded,
  (pickType, isStraightPayoutExceeded, isParlayPayoutExceeded) => {
    if (pickType === PickType.CONST_81_STRAIGHT) {
      return isStraightPayoutExceeded;
    }
    return isParlayPayoutExceeded;
  },
);

export const getInsufficientBalanceAmount = createSelector(
  getBalanceInSelectedCurrency,
  getCartRawTotalByPickType,
  (balance, rawTotalRisk) => rawTotalRisk - balance,
);

export const getIsTicketParlayTotalCoeffVisible = createSelector(
  getParlayTotalCoeff,
  getCartPickType,
  getIsSGPAllowedToSubmit,
  getIsParlayAllowedToSubmit,
  getCartItemsCount,
  (totalCoeff, pickType, isSGPAllowed, isParlayAllowed, cartItemsCount) => {
    if (totalCoeff === 0) {
      return false;
    }
    if (cartItemsCount <= 1) {
      return false;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      return isParlayAllowed;
    }
    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return isSGPAllowed;
    }
    return false;
  },
);

export const getSGPCoeffRequestFatalErrorCodes = (state: TGlobalAppState) =>
  getSportsBookConfig(state).error_codes_for_fatal_sqp_quote_error;

export const getDismissErrorCodesOnGetSGPCoeff = (state: TGlobalAppState) =>
  getSportsBookConfig(state).error_codes_for_hide_in_ticket_sqp_error;

export const getIsRecentParlaySelectionsBelongToSameGame = createSelector(
  getCartItems,
  cartItems => {
    const values = Object.values(cartItems);
    if (values.length === 0) {
      return false;
    }

    // If at least 2 belong to the same game – SGP+. Otherwise – Parlay.
    return values.reduce(
      (acc, cartItem) => {
        if (acc.found) {
          return acc;
        }
        if (acc.values.has(cartItem.event.conflict_fkey)) {
          return { ...acc, found: true };
        }
        acc.values.add(cartItem.event.conflict_fkey);
        return acc;
      },
      { values: new Set(), found: false },
    ).found;
  },
);

export const getIsMinFCPlacedAmountExceededByPickType = createSelector(
  getCartItems,
  getFCMinPickAmountByPickType,
  getCartPickType,
  getParlayPickTotalRiskAmount,
  (cartItems, minFCRiskAmount, pickType, parlayTotalRiskAmount) => {
    if (pickType === PickType.CONST_81_STRAIGHT) {
      return Object.values(cartItems).some(
        cartItem => cartItem.placed_amount__fliff_cash < minFCRiskAmount,
      );
    }

    return parlayTotalRiskAmount < minFCRiskAmount;
  },
);

export const getHaveOddsChangedState = createSelector(
  getCartItems,
  getProposalsMap,
  CoreSelectors.Events.rawEvents,
  getIsPlacedBettingEventsAvailable,
  getDidCoeffOnSubmitChange,
  getCartPickType,
  getBetSupermarket,
  (
    cartItems,
    proposalsMap,
    events,
    placedBettingAvailable,
    coeffOnSubmitDidChange,
    pickType,
    betSupermarket,
  ) => {
    const shouldAcceptChangesBeforeSubmit =
      ProposalUtils.detectChanges(
        cartItems,
        proposalsMap,
        betSupermarket,
        events,
        pickType,
      ) || !placedBettingAvailable;

    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return coeffOnSubmitDidChange || shouldAcceptChangesBeforeSubmit;
    }

    return shouldAcceptChangesBeforeSubmit;
  },
);

const getMaxNumberOfSelectionsByBPickType = createSelector(
  getSportsBookConfig,
  getCartPickType,
  (config, pickType) => {
    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return config.max_number_of_selections_in_sgp_plus_mode;
    }
    if (pickType === PickType.CONST_83_SGP_LEGACY) {
      return config.max_number_of_selections_in_sgp_mode;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      return config.max_number_of_selections_in_parlay_mode;
    }
    return config.max_number_of_selections_in_straight_mode;
  },
);

export const getMaxTotalNumberOfSelections = (state: TGlobalAppState) =>
  getSportsBookConfig(state).max_number_of_selections_total;

export const getIsBetsCountOver = createSelector(
  getCartItemsCount,
  getMaxTotalNumberOfSelections,
  (cartItemsCount, maxTotalNumberOfSelections) =>
    cartItemsCount >= maxTotalNumberOfSelections,
);

export const getIsMaxNumberOfSelectionsExceededByPickType = createSelector(
  getMaxNumberOfSelectionsByBPickType,
  getCartItemsCount,
  (maxNumberOfSelections, cartItemsCount) =>
    cartItemsCount > maxNumberOfSelections,
);

export const getMaxNumberOfSelectionsExceededDisclaimer = createSelector(
  getMaxNumberOfSelectionsByBPickType,
  getCartPickType,
  (maxNumberOfSelections, pickType) => {
    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return `Max number of selections in SGP mode is ${maxNumberOfSelections}`;
    }
    if (pickType === PickType.CONST_82_PARLAY) {
      return `Max number of selections in Parlay mode is ${maxNumberOfSelections}`;
    }
    return `Max number of selections in Straight mode is ${maxNumberOfSelections}`;
  },
);

export const getShouldUpdateSGPCoeff = createSelector(
  getShouldLoadRequestNewCoeff,
  getCartPickType,
  getCartItems,
  getIsMaxNumberOfSelectionsExceededByPickType,
  (
    shouldRequestNewCoeff,
    pickType,
    cartItems,
    isMaxNumberOfSelectionsExceeded,
  ) => {
    if (!shouldRequestNewCoeff) {
      return false;
    }
    if (pickType !== PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return false;
    }
    if (isMaxNumberOfSelectionsExceeded) {
      return false;
    }
    if (Object.keys(cartItems).length <= 1) {
      return false;
    }
    if (!PickUtils.isSGPAllowed(cartItems)) {
      return false;
    }
    return true;
  },
);

export const getIsMinimizedTicketTotalCoeffVisible = createSelector(
  getCartPickType,
  getIsParlayAllowedToSubmit,
  (pickType, isParlayAllowed) => {
    if (pickType === PickType.CONST_84_SAME_GAME_PARLAY_PLUS) {
      return true;
    }
    return isParlayAllowed;
  },
);

export const getAvailablePaymentMethods = (state: TGlobalAppState) =>
  getCustomerProfile(state).avalable_payment_vendors;

export const getRewardEarnItemDescs = createDeepEqualSelector(
  getSportsBookConfig,
  config => ({
    text1: config.server_config__text_11,
    text2: config.server_config__text_21,
  }),
);

export const getXPRequiredForBonus = (state: TGlobalAppState) =>
  getSportsBookConfig(state).xp_bonus_progress__amount_required_for_bonus;

export const getEarnedBonusXP = (state: TGlobalAppState) =>
  getCustomerProfile(state).amount_xp_bonus_progress_tokens;

export const getXPEarningProgress = createDeepEqualSelector(
  getXPRequiredForBonus,
  getEarnedBonusXP,
  (xpRequiredForBonus, earnedBonusXP) =>
    ((earnedBonusXP % xpRequiredForBonus) / xpRequiredForBonus) * 100,
);

export const getCoinsPurchaseDisclaimer = (state: TGlobalAppState) =>
  getSportsBookConfig(state).server_config__text_41;

export const getBonusCode = createSelector(getCustomerProfile, profile => {
  return profile.own_bonus_code;
});

export const getTotalMoneyPurchaseCount = (state: TGlobalAppState) =>
  getCustomerProfile(state).s_3_total_money_in_purchase_count;

export const getPersonalDetailsVerificationStatus = (state: TGlobalAppState) =>
  getCustomerProfile(state).account_details_status;

const getAuthMode = (state: TGlobalAppState) =>
  getSportsBookConfig(state).auth_mode;

export const getIsEmailSignInDisabled = createSelector(
  getAuthMode,
  authMode => authMode === 101,
);

export const makeEventAndChannelByFkey = (conflictFkey: string) =>
  createDeepEqualSelector(
    CoreSelectors.Events.rawEvents,
    CoreSelectors.Channels.allChannels,
    (events, allChannels) => {
      if (!conflictFkey) {
        return null;
      }
      const event = AppUtils.getEventByConflictKey(events, conflictFkey);
      if (!event) {
        return null;
      }
      const channel = ChannelUtils.getChannelById(allChannels, event.channelId);
      if (!channel) {
        return null;
      }

      return { event, channel };
    },
  );

export const makeMoreMarketsLoadingState = (
  event: Data__SB_SportEvent | null,
) =>
  createSelector(getBetSupermarket, supermarket => {
    return ProposalUtils.areAllMarketsLoaded(supermarket, event);
  });

export const getRenderMaxRiskAmount = createSelector(
  getCartPickType,
  getCustomerProfile,
  getCartItems,
  getSportsBookConfig,
  (pickType, { fc_limits: limits }, cartItems, config) => {
    if (pickType === PickType.CONST_81_STRAIGHT) {
      return (
        AppUtils.extractFliffCashLimit(
          limits,
          Object.values(cartItems).reduce((acc, curr) =>
            (AppUtils.extractFliffCashLimit(limits, acc?.max_risk_limit)
              ?.max_amount_in_cents ?? 0) >
            (AppUtils.extractFliffCashLimit(limits, curr?.max_risk_limit)
              ?.max_amount_in_cents ?? 0)
              ? acc
              : curr,
          ).max_risk_limit,
        )?.max_amount_in_cents ?? 0
      );
    }

    return config.fliff_cash_parlay__max_pick_amount_in_cents;
  },
);

// @todo - everything below that should be removed // refactored // adjusted to mobile code

export const getPrevWeekWonCredits = (state: TGlobalAppState) => {
  return state.sportsBook.profile.prev_week_stats.s_5007_v2_credits;
};

export const getPrevWeekSpentTokens = (state: TGlobalAppState) => {
  return state.sportsBook.profile.prev_week_stats.s_5005_v2_tokens;
};

export const getCurrentWeekWonCredits = (state: TGlobalAppState) => {
  return state.sportsBook.profile.this_week_stats.s_5007_v2_credits;
};

export const getCurrentWeekSpentTokens = (state: TGlobalAppState) => {
  return state.sportsBook.profile.this_week_stats.s_5005_v2_tokens;
};

export const getChannels = (state: TGlobalAppState) => {
  return state.sportsBook.config.channels;
};

export const getSortedChannels = createDeepEqualSelector(
  getChannels,
  channels => {
    const sortedChannels = channels.filter(channel => channel.dash_pos > 0);
    sortedChannels.sort((a, b) => a.dash_pos - b.dash_pos);
    return sortedChannels;
  },
);

export const selectEvents = (state: TGlobalAppState) => {
  return state.sportsBook.events;
};

export const getEvents = createSelector(selectEvents, events => {
  const eventsCopy = [...events];
  eventsCopy.sort((a, b) => {
    const firstStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
      a.event_start_timestamp_utc,
    );
    const secondsStampInMinutes = TimeUtils.fromMillsToMinutesStamp(
      b.event_start_timestamp_utc,
    );
    if (firstStampInMinutes === secondsStampInMinutes) {
      return (a.awayTeamName + a.homeTeamName).toLowerCase() >
        (b.awayTeamName + b.homeTeamName).toLowerCase()
        ? 1
        : -1;
    }
    return firstStampInMinutes - secondsStampInMinutes;
  });

  return eventsCopy;
});

export const selectAllChannels = (state: TGlobalAppState) => {
  return state.sportsBook.config.channels;
};

export const getAllChannels = createDeepEqualSelector(
  selectAllChannels,
  channels => channels,
);

export const getAllSportsChannels = createDeepEqualSelector(
  selectAllChannels,
  channels => {
    const sortedChannels = channels.filter(
      channel => channel.allsports_pos > 0,
    );
    sortedChannels.sort((a, b) => a.allsports_pos - b.allsports_pos);
    return sortedChannels;
  },
);
