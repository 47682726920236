import { AppUIShowModalDialogAction } from "reduxLocal/appUI/appUI.actions";
import { TFSMContactUsSubjectLine } from "server/core/data/objects";
import { TWithdrawalBankAccountType, TWithdrawProviders } from "src/types";
import { AppUtils } from "utils/AppUtils";
import { TimeUtils } from "utils/TimeUtils";
import {
  compareNewPassword,
  compareRepeatPassword,
  containsLetter,
  containsLettersNumbersSpecial,
  containsNumber,
  containsSpace,
  isAdult,
  isBetweenLength,
  isLengthRequired,
  isRequired,
  isStringAlphaNumerical,
  isStringNumerical,
  isValidEmail,
  isValidUsernamePattern,
  isWithdrawAmountInProviderRange,
  withdrawAmountBiggerBalance,
} from "utils/validators";

// -1 unknown state
// 0 invalid state
// 1 valid state
export interface IValidationReturnType<T = string> {
  value: T;
  isValid: boolean;
  validationError: string;
  state: -1 | 0 | 1;
}

class FormValidationUtils {
  validateWithdrawMethod = (
    provider: TWithdrawProviders,
  ): IValidationReturnType<TWithdrawProviders> => {
    const requiredValidation = isRequired(provider);

    if (!requiredValidation.isValid) {
      return {
        isValid: false,
        value: provider,
        validationError: "Select a prize redemption method first.",
        state: 0,
      };
    }

    return { isValid: true, value: provider, validationError: "", state: 1 };
  };

  validateAmount = (
    amount: string,
    maxAmount: number,
    provider: TWithdrawProviders,
  ): IValidationReturnType => {
    const newValue = amount.trim();

    const requiredValidation = isRequired(newValue);
    const isWithdrawAmountBiggerBalance = withdrawAmountBiggerBalance(
      +newValue,
      maxAmount / 100,
    );
    const amountInRangeValidation = isWithdrawAmountInProviderRange(
      +newValue,
      provider,
    );

    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    if (!isWithdrawAmountBiggerBalance.isValid) {
      return isWithdrawAmountBiggerBalance;
    }

    if (!amountInRangeValidation.isValid) {
      return amountInRangeValidation;
    }

    return { value: newValue, isValid: true, validationError: "", state: 1 };
  };

  validateEmail = (
    email: string,
    isEmailVerified: boolean = true,
  ): IValidationReturnType => {
    const newValue = email.trim();

    const requiredValidation = isRequired(newValue);
    const emailValidation = isValidEmail(newValue);

    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    if (!emailValidation.isValid) {
      return emailValidation;
    }

    if (!isEmailVerified) {
      return {
        value: newValue,
        isValid: false,
        validationError: "Email already exists.",
        state: 0,
      };
    }

    return { value: newValue, isValid: true, validationError: "", state: 1 };
  };

  validateCashAppTag = (cashTag: string): IValidationReturnType => {
    const requiredValidation = isRequired(cashTag);
    if (!requiredValidation.isValid) {
      return { ...requiredValidation, state: 0 };
    }

    return { value: cashTag, isValid: true, validationError: "", state: 1 };
  };

  validateRoutingNumber = (routingNumber: string): IValidationReturnType => {
    const requiredValidation = isRequired(routingNumber);
    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    const isLengthEnough = isLengthRequired(routingNumber, 9);

    if (!isLengthEnough.isValid) {
      return isLengthEnough;
    }

    return {
      value: routingNumber,
      isValid: true,
      validationError: "",
      state: 1,
    };
  };

  validateAccountNumber = (accountNumber: string): IValidationReturnType => {
    const requiredValidation = isRequired(accountNumber);
    if (!requiredValidation.isValid) {
      return { ...requiredValidation, state: 0 };
    }

    return {
      value: accountNumber,
      isValid: true,
      validationError: "",
      state: 1,
    };
  };

  validateAccountType = (
    accountType: TWithdrawalBankAccountType,
  ): IValidationReturnType<TWithdrawalBankAccountType> => {
    const isAccountTypeInputValid = !!accountType;

    if (!isAccountTypeInputValid) {
      return {
        value: accountType,
        isValid: false,
        validationError: "This field is required.",
        state: 0,
      };
    }

    return { value: accountType, isValid: true, validationError: "", state: 1 };
  };

  validateBitcoinAddress = (btcAddress: string): IValidationReturnType => {
    const isAccountTypeInputValid = !!btcAddress;

    if (!isAccountTypeInputValid) {
      return {
        value: btcAddress,
        isValid: false,
        validationError: "This field is required.",
        state: 0,
      };
    }

    return { value: btcAddress, isValid: true, validationError: "", state: 1 };
  };

  validateOldPassword = (password: string): IValidationReturnType => {
    const errorPrefix = "Your password";

    const validations = [
      isBetweenLength(password, 8, 256, errorPrefix),
      containsLetter(password, errorPrefix),
      containsNumber(password, errorPrefix),
      containsSpace(password, errorPrefix),
    ];

    for (const validation of validations) {
      if (validation && !validation.isValid) {
        return validation;
      }
    }

    return { value: password, isValid: true, validationError: "", state: 1 };
  };

  validateNewPassword = (
    newPassword: string,
    oldPassword?: string,
  ): IValidationReturnType => {
    const shouldCompareWithOldPassword = oldPassword !== undefined;

    const errorPrefix = "Your password";

    const validations = [
      isBetweenLength(newPassword, 8, 256, errorPrefix),
      containsLetter(newPassword, errorPrefix),
      containsNumber(newPassword, errorPrefix),
      containsSpace(newPassword, errorPrefix),
      shouldCompareWithOldPassword &&
        compareNewPassword(oldPassword, newPassword),
    ];

    for (const validation of validations) {
      if (validation && !validation.isValid) {
        return validation;
      }
    }

    return {
      value: newPassword,
      isValid: true,
      validationError: "",
      state: 1,
    };
  };

  validateRepeatPassword = (
    newPassword: string,
    repeatPassword: string,
  ): IValidationReturnType => {
    const newPass = newPassword.trim();
    const repeatPass = repeatPassword.trim();

    const requiredValidation = isRequired(newPass);
    const lengthValidation = isBetweenLength(newPass, 8, 256);
    const charactersValidation = containsLettersNumbersSpecial(newPass);
    const repeatPasswordValidation = compareRepeatPassword(newPass, repeatPass);

    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    if (!lengthValidation.isValid) {
      return lengthValidation;
    }

    if (!charactersValidation.isValid) {
      return charactersValidation;
    }

    if (!repeatPasswordValidation.isValid) {
      return repeatPasswordValidation;
    }

    return { value: repeatPass, isValid: true, validationError: "", state: 1 };
  };

  validateEmailMessage = (message: string): IValidationReturnType => {
    const emailMessage = message.trim();

    const requiredValidation = isRequired(emailMessage);
    const lengthValidation = isBetweenLength(emailMessage, 10, 1500);

    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    if (!lengthValidation.isValid) {
      return lengthValidation;
    }

    return {
      value: emailMessage,
      isValid: true,
      validationError: "",
      state: 1,
    };
  };

  validateSubject = (inputSubject: string): IValidationReturnType => {
    const subject = inputSubject;
    const isSubjectValid = !!subject;

    if (!isSubjectValid) {
      return {
        value: subject,
        isValid: false,
        validationError: "This field is required.",
        state: 0,
      };
    }
    return { value: subject, isValid: true, validationError: "", state: 1 };
  };

  validateCouponCode = (couponCode: string): IValidationReturnType => {
    const isCouponCodeValueEmpty = couponCode.length === 0;
    if (isCouponCodeValueEmpty) {
      return {
        isValid: true,
        value: couponCode,
        validationError: "",
        state: 0,
      };
    }
    const isCouponCodeLengthEnough = couponCode.length >= 4;
    if (isStringAlphaNumerical(couponCode) || !isCouponCodeLengthEnough) {
      AppUIShowModalDialogAction.dispatchShowErrorDialog(
        "Error",
        "Entered Promo Code is invalid.",
      );
      return {
        isValid: false,
        value: couponCode,
        validationError: "",
        state: 0,
      };
    }
    return { isValid: true, value: couponCode, validationError: "", state: 1 };
  };

  validateUsername = (
    username: string,
    isVerified: boolean,
  ): IValidationReturnType => {
    const newValue = username.trim();

    const requiredValidation = isRequired(newValue);
    const lengthValidation = isBetweenLength(newValue, 4, 32);
    const characterValidation = isValidUsernamePattern(newValue);

    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    if (!lengthValidation.isValid) {
      return lengthValidation;
    }

    if (!characterValidation.isValid) {
      return characterValidation;
    }

    if (!isVerified) {
      return {
        value: newValue,
        isValid: false,
        validationError: "Username already exists.",
        state: 0,
      };
    }

    return { value: newValue, isValid: true, validationError: "", state: 1 };
  };

  validateDateOfBirth = (dateTime: string): IValidationReturnType => {
    const requiredValidation = isRequired(dateTime);
    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    const date = TimeUtils.now(dateTime, "MM/DD/YYYY", true);

    if (!date.isValid()) {
      return {
        value: dateTime,
        isValid: false,
        validationError: "Invalid date format",
        state: 0,
      };
    }

    const isAdultValidation = isAdult(date.toISOString());

    if (!isAdultValidation.isValid) {
      return { ...isAdultValidation, value: dateTime };
    }

    return { value: dateTime, isValid: true, validationError: "", state: 1 };
  };

  validateProfileBio = (bio: string): IValidationReturnType => {
    const newValue = bio.trim();

    if (newValue === "") {
      return { value: newValue, isValid: true, validationError: "", state: 1 };
    }

    const lengthValidation = isBetweenLength(newValue, 5, 160);

    if (!lengthValidation.isValid) {
      return lengthValidation;
    }

    return { value: newValue, isValid: true, validationError: "", state: 1 };
  };

  validateBaseURL = (value: string): IValidationReturnType => {
    const newValue = value.trim();

    const requiredValidation = isRequired(newValue);
    if (!requiredValidation.isValid) {
      return requiredValidation;
    }

    return { value: newValue, isValid: true, validationError: "", state: 1 };
  };

  validateNetworkDelay = (value: string): IValidationReturnType => {
    const newValue = value.trim();
    if (newValue === "") {
      return { value: newValue, isValid: true, validationError: "", state: 1 };
    }

    const numericalValidation = isStringNumerical(newValue);

    if (!numericalValidation.isValid) {
      return numericalValidation;
    }

    return { value: newValue, isValid: true, validationError: "", state: 1 };
  };

  validateSubjectLines = (
    subjectLines: TFSMContactUsSubjectLine[],
    validationStates: IValidationReturnType[],
    index: number,
  ): IValidationReturnType[] => {
    const requiredValidation = isRequired(validationStates[index]?.value);

    if (!requiredValidation.isValid) {
      return [...validationStates, requiredValidation];
    }

    const subject = AppUtils.getSubjectLineByIndex(
      subjectLines,
      validationStates,
      index,
    );

    const nextIndex = index + 1;

    if (subject?.type === "select") {
      return this.validateSubjectLines(
        subject.subject_lines,
        validationStates,
        nextIndex,
      );
    }

    if (subject?.type !== "option") {
      const nestedRequiredValidation = isRequired(
        validationStates[nextIndex]?.value,
      );
      if (!nestedRequiredValidation.isValid) {
        return [...validationStates, nestedRequiredValidation];
      }
    }

    return validationStates;
  };
}

export default new FormValidationUtils();
