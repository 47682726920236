import RegularTextInput from "./atoms/RegularTextInput";
import SelectList from "./atoms/Select";
import RegularTextSkipValidations from "./atoms/SkipValidations";
import TextArea from "./atoms/TextArea";
import USAStateSelect from "./atoms/StateSelect";
import DateOfBirth from "./atoms/DateOfBirth";
import Phone from "./atoms/Phone";
import Code from "./atoms/Code";
import CouponCodeText from "./atoms/CouponCode";
import LabelInput from "./atoms/Label";
import DateInput from "components/atoms_new/shared/Inputs/atoms/DateInput";

const Inputs = {
  TextArea: TextArea,
  RegularText: RegularTextInput,
  Select: SelectList,
  StateSelect: USAStateSelect,
  SkipValidations: RegularTextSkipValidations,
  DateOfBirth,
  DateInput,
  Phone: Phone,
  Code: Code,
  CouponCode: CouponCodeText,
  Label: LabelInput,
};

export default Inputs;
