import classNames from "classnames";
import { IProps } from "./types";
import "./styles.scss";

const Button = ({
  label,
  onClick,
  disabled = false,
  size = "big",
  type = "primary",
  color = "white",
  containerStyle,
  labelStyle,
  shouldSkipDisabledStyle,
  children,
  className,
  contentClasses,
}: IProps) => {
  const buttonClasses = classNames(
    "button",
    `button--${size}`,
    `button--${type}`,
    className,
    {
      "button--disabled": disabled && !shouldSkipDisabledStyle,
    },
  );

  const labelClasses = classNames("button__label", `button__label--${color}`);

  const btnContentClasses = classNames("button__content", contentClasses);

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      style={containerStyle}
      disabled={disabled}>
      <div className={btnContentClasses}>
        {children}
        <span className={labelClasses} style={labelStyle}>
          {label}
        </span>
      </div>
    </button>
  );
};

export default Button;
