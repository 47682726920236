import { ValidationUtils } from "server/common/ValidationUtils";
import { AdapterHelper } from "server/common/AdapterHelper";

import {
  validatePickCurrency,
  validatePickSelectionState,
  validatePickType,
} from "server/legacyCore/data/serverEnumHelpers";
import {
  validate_SocialAccessLevel,
  validate_SocialPrivacyLevel,
  validate_StatsScoreSize,
  validate_UserType,
} from "server/social/server/impl/server_enum_helpers";
import { AppUtils } from "utils/AppUtils";
import { staticGetState } from "reduxLocal/store";

import * as I from "server/social/data/objects";
import { TAnyAlias } from "src/types";

class AdapterFSMDataMediaLink {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataMediaLink {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      link_text: ValidationUtils.assertNonEmptyString(
        jsonData.link_text,
        debugInfo + ".link_text",
      ),
      link_url: ValidationUtils.assertNonEmptyString(
        jsonData.link_url,
        debugInfo + ".link_url",
      ),
      media_logo_url: ValidationUtils.assertNonEmptyString(
        jsonData.media_logo_url,
        debugInfo + ".media_logo_url",
      ),
    };
  }
}

class AdapterPlayerLevelBanner {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevelBanner {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      icon: ValidationUtils.assertNonEmptyString(
        jsonData.icon,
        debugInfo + ".icon",
      ),
      text: ValidationUtils.assertNonEmptyString(
        jsonData.text,
        debugInfo + ".text",
      ),
    };
  }
}

class AdapterPlayerLevel {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IPlayerLevel {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      level: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.level,
        debugInfo + ".level",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      icon: ValidationUtils.assertNonEmptyString(
        jsonData.icon,
        debugInfo + ".icon",
      ),
    };
  }
}

class AdapterPlayerLevels {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevels {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      previous: jsonData.previous
        ? AdapterPlayerLevel.decode(jsonData.previous, debugInfo + ".previous")
        : null,
      current: AdapterPlayerLevel.decode(
        jsonData.current,
        debugInfo + ".current",
      ),
      next: jsonData.next
        ? AdapterPlayerLevel.decode(jsonData.next, debugInfo + ".next")
        : null,
    };
  }
}

class AdapterRPMAvatar {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IRPMAvatar {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      avatar_url: ValidationUtils.assertNonEmptyString(
        jsonData.avatar_url,
        debugInfo + ".avatar_url",
      ),
      rpm_avatar_full_body: ValidationUtils.assertNonEmptyString(
        jsonData.rpm_avatar_full_body,
        debugInfo + ".rpm_avatar_full_body",
      ),
      rpm_avatar_half_body: ValidationUtils.assertNonEmptyString(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_avatar_nav_bar: ValidationUtils.assertNonEmptyString(
        jsonData.rpm_avatar_nav_bar,
        debugInfo + ".rpm_avatar_nav_bar",
      ),
    };
  }
}

class AdapterFSMDataUserInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataUserInfo {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      first_name: ValidationUtils.assertOptionalString(
        jsonData.first_name,
        debugInfo + ".first_name",
      ),
      last_name: ValidationUtils.assertOptionalString(
        jsonData.last_name,
        debugInfo + ".last_name",
      ),
      privacy_level: validate_SocialPrivacyLevel(
        jsonData.privacy_level,
        debugInfo + ".privacy_level",
      ),
      user_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_avatar_full_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_full_body,
        debugInfo + ".rpm_avatar_full_body",
      ),
      rpm_avatar_nav_bar: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_nav_bar,
        debugInfo + ".rpm_avatar_nav_bar",
      ),
      rpm_bgcolor: ValidationUtils.assertOptionalString(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      rpm_bg_gradient: AdapterAvatarBackgroundGradient.decode(
        jsonData.rpm_bg_gradient,
        debugInfo + ".rpm_bg_gradient",
      ),
      rpm_avatars: AdapterHelper.decodeArray(
        AdapterRPMAvatar.decode,
        jsonData.rpm_avatars,
        debugInfo + ".rpm_avatars",
      ),
      count_followers: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count_followers,
        debugInfo + ".count_followers",
      ),
      count_following: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count_following,
        debugInfo + ".count_following",
      ),
      count_friends: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count_friends,
        debugInfo + ".count_friends",
      ),
      relation: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      access_level: validate_SocialAccessLevel(
        jsonData.access_level,
        debugInfo + ".access_level",
      ),
      bio: ValidationUtils.assertOptionalString(
        jsonData.bio,
        debugInfo + ".bio",
      ),
      user_type: validate_UserType(
        jsonData.user_type,
        debugInfo + ".user_type",
      ),
      media_links: AdapterHelper.decodeArray<I.IFSMDataMediaLink>(
        AdapterFSMDataMediaLink.decode,
        jsonData.media_links,
        debugInfo + ".media_links",
      ),
      banner: jsonData.banner
        ? AdapterPlayerLevelBanner.decode(
            jsonData.banner,
            debugInfo + ".banner",
          )
        : null,
      player_levels: AdapterPlayerLevels.decode(
        jsonData.player_levels,
        debugInfo + ".player_levels",
      ),
      show_personal_pick_reactions: ValidationUtils.assertBoolean(
        jsonData.show_personal_pick_reactions,
        debugInfo + ".show_personal_pick_reactions",
      ),
    };
  }
}

class AdapterUserProfileFeedResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["feed_response"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo + ".feed_response");

    return {
      data: AdapterHelper.decodeArray<I.IFSMDataActivityFeedItem>(
        AdapterFSMFSMobileDataActivityFeedItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
      merge_code: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
      arena_code: ValidationUtils.assertNonEmptyString(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
    };
  }
}

class AdapterUserProfileFollowingResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["following"] {
    if (!jsonData) {
      return { users: [] };
    }
    return {
      users: AdapterHelper.nullOrDecodeArray<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

class AdapterUserProfileFollowersResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["followers"] {
    if (!jsonData) {
      return { users: [] };
    }

    return {
      users: AdapterHelper.nullOrDecodeArray<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

class AdapterUserProfileSharedConnectionsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["shared_connections"] {
    if (!jsonData) {
      return { top_users: [], count_total: 0 };
    }

    return {
      top_users: AdapterHelper.decodeArray<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.top_users,
        debugInfo + ".top_users",
      ),
      count_total: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count_total,
        debugInfo + ".count_total",
      ),
    };
  }
}

class AdapterUserProfileBadgeBannerMaximized {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IBadgeBannerMaximized {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      icon_url: ValidationUtils.assertNonEmptyString(
        jsonData.icon_url,
        debugInfo + ".icon_url",
      ),
      text: ValidationUtils.assertNonEmptyString(
        jsonData.text,
        debugInfo + ".text",
      ),
      title: ValidationUtils.assertNonEmptyString(
        jsonData.title,
        debugInfo + ".title",
      ),
    };
  }
}

class AdapterUserProfileBadgeBannerMinimized {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IBadgeBannerMinimized {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      text: ValidationUtils.assertNonEmptyString(
        jsonData.text,
        debugInfo + ".text",
      ),
      title: ValidationUtils.assertNonEmptyString(
        jsonData.title,
        debugInfo + ".title",
      ),
    };
  }
}

class AdapterUserProfileBadgeBanner {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IBadgeBanner {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      arena_code: jsonData.arena_code
        ? ValidationUtils.assertNonEmptyString(
            jsonData.arena_code,
            debugInfo + ".arena_code",
          )
        : undefined,
      maximized_banners: AdapterHelper.decodeArray(
        AdapterUserProfileBadgeBannerMaximized.decode,
        jsonData.maximized_banners,
        debugInfo + ".maximized_banners",
      ),
      maximized_text: ValidationUtils.assertNonEmptyString(
        jsonData.maximized_text,
        debugInfo + ".maximized_text",
      ),
      maximized_title: ValidationUtils.assertNonEmptyString(
        jsonData.maximized_title,
        debugInfo + ".maximized_title",
      ),
    };
  }
}

class AdapterAvatarBackgroundGradient {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IGradientBackgroundConfig {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      colors: AdapterHelper.decodeArrayOfStrings(
        jsonData.colors,
        debugInfo + ".colors",
      ),
      modalColors: AdapterHelper.decodeArrayOfStrings(
        jsonData.modal_colors,
        debugInfo + ".modal_colors",
      ),
      sparkles: ValidationUtils.assertNonEmptyString(
        jsonData.sparkle,
        debugInfo + ".sparkle",
      ),
    };
  }
}

class AdapterFSMBadgeResponse {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IFSMBadge {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);
    return {
      code: ValidationUtils.assertNonEmptyString(
        jsonData.code,
        debugInfo + ".code",
      ),
      count: ValidationUtils.assertPositiveInteger(
        jsonData.count,
        debugInfo + ".count",
      ),
      created_timestamp_utc: ValidationUtils.assertPositiveIntegerOrNull(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      description: ValidationUtils.assertOptionalString(
        jsonData.description,
        debugInfo + ".description",
      ),
      name: ValidationUtils.assertOptionalString(
        jsonData.name,
        debugInfo + ".name",
      ),
      text: ValidationUtils.assertOptionalString(
        jsonData.text,
        debugInfo + ".text",
      ),
      image_url: ValidationUtils.assertNonEmptyString(
        jsonData.image_url,
        debugInfo + "image_url",
      ),
      weight: ValidationUtils.assertAnyInteger(
        jsonData.weight,
        debugInfo + "weight",
      ),
      short: ValidationUtils.assertNonEmptyString(
        jsonData.short,
        debugInfo + ".short",
      ),
      arena_code: ValidationUtils.assertNonEmptyString(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      details: ValidationUtils.assertOptionalString(
        jsonData.details,
        debugInfo + ".details",
      ),
    };
  }
}

export class AdapterFSMUserStatsInfoCardValueDataItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsInfoCardValueDataItem {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      value: ValidationUtils.assertNonEmptyString(
        jsonData.value,
        debugInfo + ".value",
      ),
      label: ValidationUtils.assertNonEmptyString(
        jsonData.label,
        debugInfo + ".label",
      ),
      url: jsonData.url
        ? ValidationUtils.assertOptionalString(jsonData.url, debugInfo + ".url")
        : undefined,
    };
  }
}

export class AdapterFSMUserStatsInfoCardImageDataItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsInfoCardImageDataItem {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      url: ValidationUtils.assertNonEmptyString(
        jsonData.url,
        debugInfo + ".value",
      ),
      background_color: ValidationUtils.assertNonEmptyString(
        jsonData.background_color,
        debugInfo + ".background_color",
      ),
      rpm_bg_gradient: jsonData.rpm_bg_gradient
        ? AdapterAvatarBackgroundGradient.decode(
            jsonData.rpm_bg_gradient,
            debugInfo + ".rpm_bg_gradient",
          )
        : undefined,
    };
  }
}

export class AdapterFSMUserStatsInfoCardScoreDataItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsInfoCardScoreDataItem {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      url: ValidationUtils.assertNonEmptyString(
        jsonData.url,
        debugInfo + ".url",
      ),
      score_name: ValidationUtils.assertNonEmptyString(
        jsonData.score_name,
        debugInfo + ".score_name",
      ),
      size: validate_StatsScoreSize(jsonData.size, debugInfo + ".score_value"),
      label: ValidationUtils.assertNonEmptyString(
        jsonData.label,
        debugInfo + ".label",
      ),
    };
  }
}

export class AdapterFSMUserStatsInfoCard {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsInfoCard {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      data: AdapterHelper.decodeArrayWithTypedItems(
        {
          value: AdapterFSMUserStatsInfoCardValueDataItem.decode,
          image: AdapterFSMUserStatsInfoCardImageDataItem.decode,
          score: AdapterFSMUserStatsInfoCardScoreDataItem.decode,
        },
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterFSMUserStatsGameCard {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsGameCard {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      url: ValidationUtils.assertNonEmptyString(
        jsonData.url,
        debugInfo + ".url",
      ),
      data: AdapterHelper.decodeArrayWithTypedItems(
        {
          value: AdapterFSMUserStatsInfoCardValueDataItem.decode,
          image: AdapterFSMUserStatsInfoCardImageDataItem.decode,
          score: AdapterFSMUserStatsInfoCardScoreDataItem.decode,
        },
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterFSMUserStatsFavoriteCard {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsFavoriteCard {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      value: ValidationUtils.assertNonEmptyString(
        jsonData.value,
        debugInfo + ".value",
      ),
      url: ValidationUtils.assertNonEmptyString(
        jsonData.url,
        debugInfo + ".url",
      ),
      align: ValidationUtils.assertNonEmptyString(
        jsonData.align,
        debugInfo + ".align",
      ),
    };
  }
}

export class AdapterFSMUserStatsDefaultSection {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsDefaultSection {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      info: ValidationUtils.assertOptionalString(
        jsonData.info,
        debugInfo + ".info",
      ),
      url: ValidationUtils.assertOptionalString(
        jsonData.url || "",
        debugInfo + ".url",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      cards: AdapterHelper.decodeArrayWithTypedItems(
        {
          info: AdapterFSMUserStatsInfoCard.decode,
          game: AdapterFSMUserStatsGameCard.decode,
          favorite: AdapterFSMUserStatsFavoriteCard.decode,
        },
        jsonData.cards,
        debugInfo + ".cards",
      ),
    };
  }
}

export class AdapterFSMUserStatsStatisticsDetailedCardDetails {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsStatisticsDetailedCardDetails {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      url: ValidationUtils.assertNonEmptyString(
        jsonData.url,
        debugInfo + ".url",
      ),
      value: ValidationUtils.assertNonEmptyString(
        jsonData.value,
        debugInfo + ".value",
      ),
      label: ValidationUtils.assertNonEmptyString(
        jsonData.label,
        debugInfo + ".label",
      ),
    };
  }
}

export class AdapterFSMUserStatsStatisticsDetailedCardMarketDetails {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsStatisticsDetailedCardMarketDetails {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      value: ValidationUtils.assertNonEmptyString(
        jsonData.value,
        debugInfo + ".value",
      ),
      label: ValidationUtils.assertNonEmptyString(
        jsonData.label,
        debugInfo + ".label",
      ),
    };
  }
}

export class AdapterFSMUserStatsStatisticsGeneralCard {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsStatisticsGeneralCard {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      url: ValidationUtils.assertOptionalString(
        jsonData.url || "",
        debugInfo + ".url",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      description: ValidationUtils.assertOptionalString(
        jsonData.description || "",
        debugInfo + ".description",
      ),
      data: AdapterHelper.decodeArrayWithTypedItems(
        {
          value: AdapterFSMUserStatsInfoCardValueDataItem.decode,
          image: AdapterFSMUserStatsInfoCardImageDataItem.decode,
          score: AdapterFSMUserStatsInfoCardScoreDataItem.decode,
        },
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterFSMUserStatsStatisticsDetailedCard {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsStatisticsDetailedCard {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      url: ValidationUtils.assertOptionalString(
        jsonData.url || "",
        debugInfo + ".url",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      description: ValidationUtils.assertOptionalString(
        jsonData.description || "",
        debugInfo + ".description",
      ),
      data: AdapterHelper.decodeArrayWithTypedItems(
        {
          value: AdapterFSMUserStatsInfoCardValueDataItem.decode,
          image: AdapterFSMUserStatsInfoCardImageDataItem.decode,
          score: AdapterFSMUserStatsInfoCardScoreDataItem.decode,
        },
        jsonData.data,
        debugInfo + ".data",
      ),
      details: AdapterHelper.decodeArray(
        AdapterFSMUserStatsStatisticsDetailedCardDetails.decode,
        jsonData.details,
        debugInfo + ".details",
      ),
      market_details: AdapterHelper.decodeArray(
        AdapterFSMUserStatsStatisticsDetailedCardMarketDetails.decode,
        jsonData.market_details,
        debugInfo + ".market_details",
      ),
    };
  }
}

export class AdapterFSMUserStatsStatisticsFilter {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsStatisticsFilter {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      value: ValidationUtils.assertNonEmptyString(
        jsonData.value,
        debugInfo + ".value",
      ),
      label: ValidationUtils.assertNonEmptyString(
        jsonData.label,
        debugInfo + ".label",
      ),
    };
  }
}

export class AdapterFSMUserStatsStatisticsSection {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsStatisticsSection {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      info: ValidationUtils.assertOptionalString(
        jsonData.info,
        debugInfo + ".info",
      ),
      url: ValidationUtils.assertOptionalString(
        jsonData.url || "",
        debugInfo + ".url",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      filters: AdapterHelper.decodeArray(
        AdapterFSMUserStatsStatisticsFilter.decode,
        jsonData.filters,
        debugInfo + ".filters",
      ),
      data: AdapterHelper.decodeMapWithArrayWithTypedItems(
        {
          statistics_general: AdapterFSMUserStatsStatisticsGeneralCard.decode,
          statistics_detailed: AdapterFSMUserStatsStatisticsDetailedCard.decode,
        },
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterFSMUserStatsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUserStatsResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      sections: AdapterHelper.decodeArrayWithTypedItems(
        {
          vertical: AdapterFSMUserStatsDefaultSection.decode,
          horizontal: AdapterFSMUserStatsDefaultSection.decode,
          statistics: AdapterFSMUserStatsStatisticsSection.decode,
        },
        jsonData.sections,
        debugInfo + ".sections",
      ),
    };
  }
}

export class AdapterFSMGetUserProfileResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_User_Profile__Response",
      profile: AdapterFSMDataUserInfo.decode(
        jsonData.profile,
        debugInfo + ".profile",
      ),
      user_stats_response: AdapterFSMUserStatsResponse.decode(
        jsonData.user_stats_response,
        debugInfo + ".user_stats_response",
      ),
      feed_response: AdapterUserProfileFeedResponse.decode(
        jsonData.feed_response,
        debugInfo + ".feed_response",
      ),
      following: AdapterUserProfileFollowingResponse.decode(
        jsonData.following,
        debugInfo + ".following",
      ),
      followers: AdapterUserProfileFollowersResponse.decode(
        jsonData.followers,
        debugInfo + ".followers",
      ),
      shared_connections: AdapterUserProfileSharedConnectionsResponse.decode(
        jsonData.shared_connections,
        debugInfo + ".shared_connections",
      ),
      badges: AdapterHelper.decodeArray<I.IFSMBadge>(
        AdapterFSMBadgeResponse.decode,
        jsonData.badges,
        debugInfo + ".badges",
      ),
      badge_banner_data: AdapterHelper.decodeArray(
        AdapterUserProfileBadgeBanner.decode,
        jsonData.badge_banner_data,
        debugInfo + ".badge_banner_data",
      ),
    };
  }
}

export class AdapterFSMModifyRelationshipResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMModifyRelationshipResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      observer_profile: AdapterFSMDataUserInfo.decode(
        jsonData.observer_profile,
        debugInfo + ".observer_profile",
      ),
      anchor_profile: AdapterFSMDataUserInfo.decode(
        jsonData.anchor_profile,
        debugInfo + ".anchor_profile",
      ),
      // this is actually transport detail, not a member
      __object_class_name: ValidationUtils.assertNonEmptyString(
        jsonData.__object_class_name,
        debugInfo + ".__object_class_name",
      ),
    };
  }
}

class AdapterFSMPickSelection {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataUserPickSelectionInfo {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    if (!jsonData.proposal_fkey) {
      jsonData.proposal_fkey = "unknown_proposal_fkey";
    }
    if (!jsonData.conflict_fkey) {
      jsonData.conflict_fkey = "unknown_conflict_fkey";
    }
    if (!jsonData.market_fkey) {
      jsonData.market_fkey = "unknown_market_fkey";
    }
    if (!jsonData.channel_name) {
      jsonData.channel_name = "";
    }

    const state = staticGetState();
    const eventByFkey = AppUtils.getEventByConflictKey(
      state.sportsBook.events,
      jsonData.conflict_fkey,
    );

    return {
      settled_timestamp_utc: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validatePickSelectionState(jsonData.state, debugInfo + ".state"),
      channel_id: ValidationUtils.assertPositiveInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      coeff: ValidationUtils.assertAnyInteger(
        jsonData.coeff,
        debugInfo + ".coeff",
      ),
      is_conflict_in_play: !!eventByFkey?.isLive,
      in_play_state_desc: eventByFkey?.live_state_desc || "",
      is_copy_available: false,
      proposal_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      conflict_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      id: ValidationUtils.assertPositiveInteger(
        jsonData.id || 1,
        debugInfo + ".id",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      // 2020-10-25 / Ivan / moving to new set of texts
      t_141_selection_name: ValidationUtils.assertNonEmptyString(
        jsonData.selection_name,
        debugInfo + ".t_141_selection_name",
      ),
      t_142_selection_param_1: ValidationUtils.assertOptionalString(
        jsonData.selection_param_1,
        debugInfo + ".t_142_selection_param_1",
      ),
      t_131_market_name: ValidationUtils.assertNonEmptyString(
        jsonData.market_name,
        debugInfo + ".t_131_market_name",
      ),
      t_132_market_note: ValidationUtils.assertOptionalString(
        jsonData.market_note,
        debugInfo + ".t_132_market_note",
      ),
      t_121_event_info: ValidationUtils.assertNonEmptyString(
        jsonData.event_info,
        debugInfo + ".t_121_event_info",
      ),
      channel_name: ValidationUtils.assertOptionalString(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
    };
  }
}

class AdapterFSMFSMobileDataActivityFeedItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataActivityFeedItem {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);
    return {
      pick_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.pick_fkey,
        debugInfo + ".pick_fkey",
      ),
      user_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      created_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      settled_timestamp_utc: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validatePickSelectionState(jsonData.state, debugInfo + ".state"),
      type: validatePickType(jsonData.type, debugInfo + ".type"),
      currency: validatePickCurrency(
        jsonData.currency,
        debugInfo + ".currency",
      ),
      risk_amount: ValidationUtils.assertPositiveInteger(
        jsonData.risk_amount,
        debugInfo + ".risk_amount",
      ),
      payout_amount: ValidationUtils.assertAnyInteger(
        jsonData.payout_amount,
        debugInfo + ".payout_amount",
      ),
      coeff: ValidationUtils.assertAnyInteger(
        jsonData.us_coeff,
        debugInfo + ".us_coeff",
      ),
      selections: AdapterHelper.decodeArray<I.IFSMDataUserPickSelectionInfo>(
        AdapterFSMPickSelection.decode,
        jsonData.selections,
        debugInfo + ".selections",
      ),
      username: ValidationUtils.assertOptionalString(
        jsonData.username,
        debugInfo + ".username",
      ),
      user_type: validate_UserType(
        jsonData.user_type,
        debugInfo + ".user_type",
      ),
      rpm_avatar_half_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_bgcolor: ValidationUtils.assertOptionalString(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      rpm_bg_gradient: AdapterAvatarBackgroundGradient.decode(
        jsonData.rpm_bg_gradient,
        debugInfo + ".rpm_bg_gradients",
      ),
      relation: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      stake_category: ValidationUtils.assertPositiveInteger(
        jsonData.stake_category,
        debugInfo + ".stake_category",
      ),
      player_level_icon: ValidationUtils.assertOptionalString(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      copy_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.copy_count,
        debugInfo + ".copy_count",
      ),
      reactions_count: jsonData.reactions_count
        ? ValidationUtils.assertPositiveIntegerOrZero(
            jsonData.reactions_count,
            debugInfo + ".reactions_count",
          )
        : 0,
      emoji_mapping: jsonData.emoji_mapping
        ? AdapterHelper.decodeStringMap(
            AdapterEmojiData.decode,
            jsonData.emoji_mapping,
            debugInfo + ".emoji_mapping",
          )
        : null,
    };
  }
}

export class AdapterFSMUpdateUserProfileResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUpdateUserProfileResponse {
    return {
      __object_class_name: "FSM__Update_User_Profile_V2__Response",
      profile: AdapterFSMDataUserInfo.decode(jsonData.profile, debugInfo),
    };
  }
}

class AdapterFSMSearchUserInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMSearchUserInfo {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_bgcolor: ValidationUtils.assertOptionalString(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      rpm_bg_gradient: AdapterAvatarBackgroundGradient.decode(
        jsonData.rpm_bg_gradient,
        debugInfo + ".rpm_bg_gradient",
      ),
      count_followers: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count_followers,
        debugInfo + ".count_followers",
      ),
      count_following: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count_following,
        debugInfo + ".count_following",
      ),
      relation: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      access_level: validate_SocialAccessLevel(
        jsonData.access_level,
        debugInfo + ".access_level",
      ),
      player_level_icon: ValidationUtils.assertOptionalString(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      user_type: validate_UserType(
        jsonData.user_type,
        debugInfo + ".user_type",
      ),
    };
  }
}

export class AdapterFSMSearchUserResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMSearchUserResponse {
    return {
      __object_class_name: "FSM__Search_User__Response",
      users: AdapterHelper.decodeArray<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

export class AdapterFSMGetUsersForRelationshipResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUsersForRelationshipResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Users_For_Relationship__Response",
      users: AdapterHelper.decodeArray<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

class AdapterFSMLeaderboardReport {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMLeaderboardPeriodMeta {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      period_start_stamp_seconds_utc: ValidationUtils.assertPositiveInteger(
        jsonData.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_visual_start_date: ValidationUtils.assertNonEmptyString(
        jsonData.period_visual_start_date,
        debugInfo + ".period_visual_start_date",
      ),
      period_visual_end_date: ValidationUtils.assertNonEmptyString(
        jsonData.period_visual_end_date,
        debugInfo + ".period_visual_end_date",
      ),
      report_anchor_stamp_seconds_utc: ValidationUtils.assertPositiveInteger(
        jsonData.report_anchor_stamp_seconds_utc,
        debugInfo + ".report_anchor_stamp_seconds_utc",
      ),
      report_anchor_offset: ValidationUtils.assertAnyInteger(
        jsonData.report_anchor_offset,
        debugInfo + ".report_anchor_offset",
      ),
      has_prev: ValidationUtils.assertBoolean(
        jsonData.has_prev,
        debugInfo + ".has_prev",
      ),
      has_next: ValidationUtils.assertBoolean(
        jsonData.has_next,
        debugInfo + ".has_next",
      ),
    };
  }
}

class AdapterFSMDataLeaderboardItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataLeaderboardItem {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_avatar_full_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_full_body,
        debugInfo + ".rpm_avatar_full_body",
      ),
      rpm_bgcolor: ValidationUtils.assertOptionalString(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      rpm_bg_gradient: AdapterAvatarBackgroundGradient.decode(
        jsonData.rpm_bg_gradient,
        debugInfo + ".rpm_bg_gradient",
      ),
      social_amount_won: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.social_amount_won,
        debugInfo + ".count_followers",
      ),
      rank: ValidationUtils.assertAnyInteger(
        jsonData.rank,
        debugInfo + ".rank",
      ),
      relation: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      badge_icon_url: ValidationUtils.assertOptionalString(
        jsonData.badge_icon_url,
        debugInfo + ".badge_icon_url",
      ),
      player_level_icon: ValidationUtils.assertNonEmptyString(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      hit_rate: ValidationUtils.assertPositiveFloatOrNull(
        jsonData.hit_rate,
        debugInfo + ".hit_rate",
      ),
      user_type: validate_UserType(
        jsonData.user_type,
        debugInfo + ".user_type",
      ),
      score: jsonData.score
        ? ValidationUtils.assertOptionalString(
            jsonData.score,
            debugInfo + ".score",
          )
        : null,
    };
  }
}

export class AdapterFSMGetLeaderboardResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetLeaderboardResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Leaderboard__Response",
      arena_code: ValidationUtils.assertNonEmptyString(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      data: AdapterHelper.decodeArray<I.IFSMDataLeaderboardItem>(
        AdapterFSMDataLeaderboardItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
      period_meta: AdapterFSMLeaderboardReport.decode(
        jsonData.period_meta,
        debugInfo + ".period_meta",
      ),
      profile: jsonData.profile
        ? AdapterFSMDataUserInfo.decode(
            jsonData.profile,
            debugInfo + ".profile",
          )
        : null,
      current_user: jsonData.current_user
        ? AdapterFSMDataLeaderboardItem.decode(
            jsonData.current_user,
            debugInfo + ".current_user",
          )
        : null,
    };
  }
}

class AdapterFSMDataPlayerLevelLeaderboardItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataPlayerLevelLeaderboardItem {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      social_amount_won: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.social_amount_won,
        debugInfo + ".social_amount_won",
      ),
      rank: ValidationUtils.assertAnyInteger(
        jsonData.rank,
        debugInfo + ".rank",
      ),
      relation: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      upgrading: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.upgrading,
        debugInfo + ".upgrading",
      ),
      hit_rate: ValidationUtils.assertPositiveFloatOrNull(
        jsonData.hit_rate,
        debugInfo + ".hit_rate",
      ),
      score: jsonData.score
        ? ValidationUtils.assertOptionalString(
            jsonData.score,
            debugInfo + ".score",
          )
        : null,
    };
  }
}

class AdapterPlayerLevelDescription {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevelDescription {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      icon_url: ValidationUtils.assertNonEmptyString(
        jsonData.icon_url,
        debugInfo + ".icon_url",
      ),
      title: ValidationUtils.assertNonEmptyString(
        jsonData.title,
        debugInfo + ".title",
      ),
    };
  }
}

class AdapterPlayerLevelsBanner {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevelsBanner {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      arena_code: ValidationUtils.assertNonEmptyString(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      maximized_banners: AdapterHelper.decodeArray(
        AdapterPlayerLevelDescription.decode,
        jsonData.maximized_banners,
        debugInfo + ".maximized_banners",
      ),
      maximized_text: ValidationUtils.assertNonEmptyString(
        jsonData.maximized_text,
        debugInfo + ".maximized_text",
      ),
      maximized_title: ValidationUtils.assertNonEmptyString(
        jsonData.maximized_title,
        debugInfo + ".maximized_title",
      ),
      minimized_banner: AdapterUserProfileBadgeBannerMinimized.decode(
        jsonData.minimized_banner,
        debugInfo + ".minimized_banner",
      ),
    };
  }
}

export class AdapterFSMGetPlayerLevelLeaderboardResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetPlayerLevelLeaderboardResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Player_Level_Leaderboard_V2__Response",
      arena_code: ValidationUtils.assertNonEmptyString(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      top_users:
        AdapterHelper.decodeArray<I.IFSMDataPlayerLevelLeaderboardItem>(
          AdapterFSMDataPlayerLevelLeaderboardItem.decode,
          jsonData.top_users,
          debugInfo + ".top_users",
        ),
      middle_users:
        AdapterHelper.decodeArray<I.IFSMDataPlayerLevelLeaderboardItem>(
          AdapterFSMDataPlayerLevelLeaderboardItem.decode,
          jsonData.middle_users,
          debugInfo + ".middle_users",
        ),
      bottom_users:
        AdapterHelper.decodeArray<I.IFSMDataPlayerLevelLeaderboardItem>(
          AdapterFSMDataPlayerLevelLeaderboardItem.decode,
          jsonData.bottom_users,
          debugInfo + ".bottom_users",
        ),
      period_meta: AdapterFSMLeaderboardReport.decode(
        jsonData.period_meta,
        debugInfo + ".period_meta",
      ),
      profile: jsonData.profile
        ? AdapterFSMDataUserInfo.decode(
            jsonData.profile,
            debugInfo + ".profile",
          )
        : null,
      current_user: jsonData.current_user
        ? AdapterFSMDataPlayerLevelLeaderboardItem.decode(
            jsonData.current_user,
            debugInfo + ".current_user",
          )
        : null,
      player_level_leaderboard_banner: AdapterPlayerLevelsBanner.decode(
        jsonData.player_level_leaderboard_banner,
        debugInfo + ".player_level_leaderboard_banner",
      ),
    };
  }
}

export class AdapterFSMGetActivityFeedMetaResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetActivityFeedMetaResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Activity_Feed_Meta__Response",
      newest_feed_key: ValidationUtils.assertOptionalString(
        jsonData.newest_feed_key,
        debugInfo,
      ),
    };
  }
}

export class AdapterFSMGetActivityFeedDataResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetActivityFeedDataResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Activity_Feed_Data__Response",
      data: AdapterHelper.decodeArray<I.IFSMDataActivityFeedItem>(
        AdapterFSMFSMobileDataActivityFeedItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
      merge_code: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
    };
  }
}

export class AdapterFSMGetUserBadgesResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserBadgesResponse {
    return {
      __object_class_name: "FSM__Get_User_Badges__Response",
      badges: AdapterHelper.decodeArray<I.IFSMBadge>(
        AdapterFSMBadgeResponse.decode,
        jsonData.badges,
        debugInfo + ".badges",
      ),
    };
  }
}

class AdapterFSMSocialConfig {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMSocialConfig {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      config_version: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.config_version,
        debugInfo + ".config_version",
      ),
      score_icon: ValidationUtils.assertNonEmptyString(
        jsonData.score_icon,
        debugInfo + ".score_icon",
      ),
      hit_rate_icon: ValidationUtils.assertNonEmptyString(
        jsonData.hit_rate_icon,
        debugInfo + ".hit_rate_icon",
      ),
      supported_emojis: jsonData.supported_emojis
        ? AdapterHelper.decodeArrayOfStrings(
            jsonData.supported_emojis,
            debugInfo + ".supported_emojis",
          )
        : [],
      rpm_bg_gradients: AdapterHelper.decodeArray(
        AdapterAvatarBackgroundGradient.decode,
        jsonData.rpm_bg_gradients,
        debugInfo + ".rpm_bg_gradients",
      ),
      lobby_chat_id: ValidationUtils.assertNonEmptyString(
        jsonData.lobby_chat_id,
        debugInfo + ".lobby_chat_id",
      ),
      enable_pick_reactions: ValidationUtils.assertBoolean(
        jsonData.enable_pick_reactions,
        debugInfo + "enable_pick_reactions",
      ),
    };
  }
}

export class AdapterFSMProtocolResponseSlots {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMProtocolResponseSlots {
    return {
      social_config: AdapterHelper.nullOrDecode<I.IFSMSocialConfig>(
        AdapterFSMSocialConfig.decode,
        jsonData.social_config,
        debugInfo + ".social_config",
      ),
    };
  }
}

export class AdapterFSMGetSuggestedFollowersResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetSuggestedFollowsResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Suggested_Follows__Response",
      users: AdapterHelper.decodeArray(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

class AdapterFSMEventStatsGroupLine {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsGroupLine {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      line_category: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.line_category,
        debugInfo + ".line_category",
      ),
      line_header: ValidationUtils.assertOptionalString(
        jsonData.line_header,
        debugInfo + ".line_header",
      ),
      option_name_1: ValidationUtils.assertOptionalString(
        jsonData.option_name_1,
        debugInfo + ".option_name_1",
      ),
      option_name_2: ValidationUtils.assertOptionalString(
        jsonData.option_name_2,
        debugInfo + ".option_name_2",
      ),
      option_value_1: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.option_value_1,
        debugInfo + ".option_value_1",
      ),
      option_value_2: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.option_value_2,
        debugInfo + ".option_value_2",
      ),
      skin: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.skin,
        debugInfo + ".skin",
      ),
    };
  }
}

class AdapterFSMEventStatsProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsProposal {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      event_info: ValidationUtils.assertNonEmptyString(
        jsonData.event_info,
        debugInfo + ".event_info",
      ),
      event_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      event_start_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      market_name: ValidationUtils.assertNonEmptyString(
        jsonData.market_name,
        debugInfo + ".market_name",
      ),
      channel_id: ValidationUtils.assertAnyInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      proposal_type: ValidationUtils.assertPositiveInteger(
        jsonData.proposal_type,
        debugInfo + ".proposal_type",
      ),
      proposal_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      group_fkey: ValidationUtils.assertOptionalString(
        jsonData.group_fkey,
        debugInfo + ".group_fkey",
      ),
      selection_name: ValidationUtils.assertNonEmptyString(
        jsonData.selection_name,
        debugInfo + ".selection_name",
      ),
      total_social_coins_risk_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.total_social_coins_risk_amount,
          debugInfo + ".total_social_coins_risk_amount",
        ),
      total_social_picks: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
    };
  }
}

class AdapterFSMEventStatsGroup {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsGroup {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      group_category: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.group_category,
        debugInfo + ".group_category",
      ),
      group_header: ValidationUtils.assertOptionalString(
        jsonData.group_header,
        debugInfo + ".group_header",
      ),
      lines: AdapterHelper.decodeArray(
        AdapterFSMEventStatsGroupLine.decode,
        jsonData.lines,
        debugInfo + ".lines",
      ),
    };
  }
}

class AdapterFSMEventStatsProposalsGroup {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsProposalsGroup {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      group_category: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.group_category,
        debugInfo + ".group_category",
      ),
      group_header: ValidationUtils.assertOptionalString(
        jsonData.group_header,
        debugInfo + ".group_header",
      ),
      proposals: AdapterHelper.decodeArray(
        AdapterFSMEventStatsProposal.decode,
        jsonData.proposals,
        debugInfo + ".proposals",
      ),
    };
  }
}

class AdapterFSMEventStatsFriendsAndFollowsProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMFriendsAndFollowsProposal {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assertOptionalString(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_bgcolor: ValidationUtils.assertOptionalString(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      rpm_bg_gradient: AdapterAvatarBackgroundGradient.decode(
        jsonData.rpm_bg_gradient,
        debugInfo + ".rpm_bg_gradient",
      ),
      player_level_icon: ValidationUtils.assertNonEmptyString(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      event_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      market_name: ValidationUtils.assertNonEmptyString(
        jsonData.market_name,
        debugInfo + ".market_name",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      proposal_type: ValidationUtils.assertPositiveInteger(
        jsonData.proposal_type,
        debugInfo + ".proposal_type",
      ),
      proposal_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      group_fkey: ValidationUtils.assertOptionalString(
        jsonData.group_fkey,
        debugInfo + ".group_fkey",
      ),
      relation: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      selection_name: ValidationUtils.assertNonEmptyString(
        jsonData.selection_name,
        debugInfo + ".selection_name",
      ),
      submission_stamp_millis_utc: ValidationUtils.assertAnyInteger(
        jsonData.submission_stamp_millis_utc,
        debugInfo + ".submission_stamp_millis_utc",
      ),
    };
  }
}

class AdapterFSMPopularParlayProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMPopularParlayProposal {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      event_info: ValidationUtils.assertNonEmptyString(
        jsonData.event_info,
        debugInfo + ".event_info",
      ),
      event_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      event_start_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      market_name: ValidationUtils.assertNonEmptyString(
        jsonData.market_name,
        debugInfo + ".market_name",
      ),
      market_note: ValidationUtils.assertOptionalString(
        jsonData.market_note,
        debugInfo + ".market_note",
      ),
      channel_id: ValidationUtils.assertAnyInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      market_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      proposal_type: ValidationUtils.assertPositiveInteger(
        jsonData.proposal_type,
        debugInfo + ".proposal_type",
      ),
      proposal_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      group_fkey: ValidationUtils.assertOptionalString(
        jsonData.group_fkey,
        debugInfo + ".group_fkey",
      ),
      selection_name: ValidationUtils.assertNonEmptyString(
        jsonData.selection_name,
        debugInfo + ".selection_name",
      ),
      channel_name: ValidationUtils.assertNonEmptyString(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
      sport_id: ValidationUtils.assertPositiveInteger(
        jsonData.sport_id,
        debugInfo + ".sport_id",
      ),
    };
  }
}

class AdapterFSMPopularParlay {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMPopularParlay {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      pick_type: validatePickType(jsonData.pick_type, debugInfo + ".pick_type"),
      total_social_coins_risk_amount: ValidationUtils.assertPositiveInteger(
        jsonData.total_social_coins_risk_amount,
        debugInfo + ".total_social_coins_risk_amount",
      ),
      total_social_picks: ValidationUtils.assertPositiveInteger(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
      us_coeff: ValidationUtils.assertAnyInteger(
        jsonData.us_coeff,
        debugInfo + ".us_coeff",
      ),
      proposals: AdapterHelper.decodeArray(
        AdapterFSMPopularParlayProposal.decode,
        jsonData.proposals,
        debugInfo + ".proposals",
      ),
    };
  }
}

class AdapterFSMEventStatsInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsInfo {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      away_team: ValidationUtils.assertOptionalString(
        jsonData.away_team,
        debugInfo + ".away_team",
      ),
      channel_id: ValidationUtils.assertAnyInteger(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      channel_name: ValidationUtils.assertOptionalString(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
      event_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      event_info: ValidationUtils.assertOptionalString(
        jsonData.event_info,
        debugInfo + ".event_info",
      ),
      event_start_timestamp_utc: ValidationUtils.assertAnyInteger(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      home_team: ValidationUtils.assertOptionalString(
        jsonData.home_team,
        debugInfo + ".home_team",
      ),
      is_home_visually_first: ValidationUtils.assertBoolean(
        jsonData.is_home_visually_first,
        debugInfo + ".is_home_visually_first",
      ),
    };
  }
}

class AdapterFSMEventStats {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStats {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);
    return {
      total_social_picks: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
      total_social_coins_risk_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.total_social_coins_risk_amount,
          debugInfo + ".total_social_coins_risk_amount",
        ),
      info: AdapterFSMEventStatsInfo.decode(jsonData.info, debugInfo + ".info"),
    };
  }
}

class AdapterFSMEventDetailsStats {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventDetailsStats {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);
    return {
      total_social_picks: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
      total_social_coins_risk_amount:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.total_social_coins_risk_amount,
          debugInfo + ".total_social_coins_risk_amount",
        ),
      groups: AdapterHelper.decodeArray(
        AdapterFSMEventStatsGroup.decode,
        jsonData.groups,
        debugInfo + ".groups",
      ),
      info: AdapterFSMEventStatsInfo.decode(jsonData.info, debugInfo + ".info"),
    };
  }
}

export class AdapterFSMGetEventStatsDetailResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetEventStatsDetailResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Event_Stats_Detail__Response",
      stats_event: AdapterFSMEventDetailsStats.decode(
        jsonData.stats_event,
        debugInfo + ".stats_event",
      ),
      proposals_groups: AdapterHelper.decodeArray(
        AdapterFSMEventStatsProposalsGroup.decode,
        jsonData.proposals_groups,
        debugInfo + ".proposals_groups",
      ),
      friends_and_follows_proposals: AdapterHelper.decodeArray(
        AdapterFSMEventStatsFriendsAndFollowsProposal.decode,
        jsonData.friends_and_follows_proposals,
        debugInfo + ".friends_and_follows_proposals",
      ),
    };
  }
}

export class AdapterFSMGetTopEventStatsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetTopEventStatsResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Top_Event_Stats__Response",
      stats_events: AdapterHelper.decodeArray(
        AdapterFSMEventStats.decode,
        jsonData.stats_events,
        debugInfo + ".stats_events",
      ),
      proposals_groups: AdapterHelper.decodeArray(
        AdapterFSMEventStatsProposalsGroup.decode,
        jsonData.proposals_groups,
        debugInfo + ".proposals_groups",
      ),
      popular_parlays: AdapterHelper.decodeArray(
        AdapterFSMPopularParlay.decode,
        jsonData.popular_parlays,
        debugInfo + ".popular_parlays",
      ),
    };
  }
}

export class AdapterFSMGetCopyPickUsersResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetCopyPickUsersResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Copy_Pick_Users__Response",
      users: AdapterHelper.decodeArray<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
      hidden_copy_count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.hidden_copy_count,
        debugInfo + ".hidden_copy_count",
      ),
    };
  }
}

export class AdapterFSMGetPickEngagementResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserPickEngagementResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_User_Pick_Engagement__Response",
      reactions: AdapterHelper.decodeStringMap(
        AdapterEmojiMapping.decode,
        jsonData.reactions,
        debugInfo + ".reactions",
      ),
      copy_counters: AdapterHelper.decodeStringMap(
        ValidationUtils.assertPositiveIntegerOrZero,
        jsonData.copy_counters,
        debugInfo + ".copy_counters",
      ),
    };
  }
}

export class AdapterFSMReactPickResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMReactPickResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__React_Pick__Response",
      emoji: jsonData.emoji
        ? ValidationUtils.assertNonEmptyString(
            jsonData.emoji,
            debugInfo + ".emoji",
          )
        : undefined,
      pick_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.pick_fkey,
        debugInfo + ".pick_fkey",
      ),
    };
  }
}

export class AdapterEmojiData {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IEmojiData {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count,
        debugInfo + ".count",
      ),
      is_mine: ValidationUtils.assertBoolean(
        jsonData.is_mine,
        debugInfo + ".is_mine",
      ),
    };
  }
}

class AdapterEmojiMapping {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IMyPickEmojiData {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      emoji_mapping: AdapterHelper.decodeStringMap(
        AdapterEmojiData.decode,
        jsonData.emoji_mapping,
        debugInfo + ".emoji_mapping",
      ),
      count: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.count,
        debugInfo + ".count",
      ),
    };
  }
}

export class AdapterFSMGetChatTokenResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetTokenResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Chat_Token__Response",
      token: ValidationUtils.assertOptionalString(
        jsonData.token,
        debugInfo + ".token",
      ),
    };
  }
}

export class AdapterFSMGetPickResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetActivityFeedItemDataResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Pick__Response",
      pick: AdapterFSMFSMobileDataActivityFeedItem.decode(
        jsonData.pick,
        debugInfo + ".pick",
      ),
    };
  }
}
