import { createBrowserHistory } from "history";
import useDidMount from "hooks/lifecycles/useDidMount";
import { useRef } from "react";
import { Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import URLUtils from "utils/URLUtils";

interface IProps {
  children: React.ReactNode;
}

const history = createBrowserHistory();

const RootRouter = ({ children }: IProps) => {
  const isHistoryStackEmpty = useRef(true);

  useDidMount(() => {
    const customGoBack = () => {
      if (isHistoryStackEmpty.current) {
        history.replace("/sports");
        isHistoryStackEmpty.current = false;
      } else {
        // Perform default goBack behavior
        window.history.go(-1);
      }
    };

    history.goBack = customGoBack;

    history.listen(() => {
      // Reset the flag on first navigation
      isHistoryStackEmpty.current = false;
    });
  });

  return (
    <Router history={history}>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: URLUtils.parse,
          objectToSearchString: URLUtils.stringify,
          updateType: "replaceIn",
        }}>
        {children}
      </QueryParamProvider>
    </Router>
  );
};

export default RootRouter;
