import { ValidationUtils } from "server/common/ValidationUtils";
import {
  AdapterSBConfig,
  AdapterSBUserProfile,
  AdapterSBXDeltaProfile,
  AdapterSlideUserGiftCard,
  AdapterSBUnfinishedOrder,
  AdapterSBPick,
} from "server/legacyCore/data/serverAdapters";

import {
  Data__SB_Config,
  Data__SB_SportEvent,
  Data__SB_UserProfile,
  IDataSBPick,
  IDataSBXDeltaProfile,
  IDataSlideUserGiftCard,
} from "server/legacyCore/data/objects";
import * as I from "server/core/data/objects";
import { TransactionType } from "server/core/data/constants";
import {
  validatePaymentMethodInstrumentStatus,
  validatePaymentMethodInstrumentType,
  validatePodCreditableState,
  validatePodPlayableState,
  validatePodProgressState,
  validateTransactionType,
  validatePlacePicksOperationStatus,
} from "server/core/server/impl/serverEnumHelpers";
import {
  IDataSBUnfinishedOrder,
  IFCMPlayer,
  IFCMPlayers,
} from "server/common/data/objects";
import {
  AdapterEvent,
  AdapterFeedDiffUpdate,
} from "server/common/data/serverAdapters";
import { AdapterHelper } from "server/common/AdapterHelper";
import { TAnyAlias } from "src/types";

class AdapterFCMPingResponseData {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPingResponse["data"] {
    return {
      min_app_build: ValidationUtils.assertPositiveInteger(
        jsonData.min_app_build,
        debugInfo + ".min_app_build",
      ),
      server_build: ValidationUtils.assertPositiveInteger(
        jsonData.server_build,
        debugInfo + ".server_build",
      ),
      server_timestamp: ValidationUtils.assertPositiveInteger(
        jsonData.server_timestamp,
        debugInfo + ".server_timestamp",
      ),
      server_instance_info: ValidationUtils.assertNonEmptyString(
        jsonData.server_instance_info,
        debugInfo + ".server_instance_info",
      ),
      usa_state_restrictions: AdapterHelper.decodeArray(
        AdapterUSAStateRestrictions.decode,
        jsonData.usa_state_restrictions,
        debugInfo + ".usa_state_restrictions",
      ),
      core_sever_public_endpoint: ValidationUtils.assertOptionalString(
        jsonData.core_sever_public_endpoint,
        ".core_sever_public_endpoint",
      ),
      core_sever_private_endpoint: ValidationUtils.assertOptionalString(
        jsonData.core_sever_private_endpoint,
        ".core_sever_private_endpoint",
      ),
      proposals_feed_endpoint: ValidationUtils.assertOptionalString(
        jsonData.proposals_feed_endpoint,
        ".proposals_feed_endpoint",
      ),
      device_location_vendor: ValidationUtils.assertNonEmptyString(
        jsonData.device_location_vendor,
        debugInfo + ".device_location_vendor",
      ),
      is_alt_prop_button_enabled: ValidationUtils.assertBoolean(
        jsonData.is_alt_prop_button_enabled,
        debugInfo + ".is_alt_prop_button_enabled",
      ),
    };
  }
}

export class AdapterFCMPingResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPingResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Ping__Response",
      data: AdapterFCMPingResponseData.decode(
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

class AdapterUSAStateRestrictions {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IUSAStateRestriction {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      restriction_code: ValidationUtils.assertPositiveInteger(
        jsonData.restriction_code,
        debugInfo + ".restriction_code",
      ),
      restriction_message: ValidationUtils.assertNonEmptyString(
        jsonData.restriction_message,
        debugInfo + ".restriction_message",
      ),
      usa_state_code: ValidationUtils.assertNonEmptyString(
        jsonData.usa_state_code,
        debugInfo + ".usa_state_code",
      ),
    };
  }
}

export class AdapterPlayer {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IFCMPlayer {
    return {
      image: ValidationUtils.assertOptionalString(
        jsonData.image,
        debugInfo + ".image",
      ),
    };
  }
}

export class AdapterPlayers {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IFCMPlayers {
    if (!jsonData) {
      return {};
    }

    const players: IFCMPlayers = {};

    for (const [key, player] of Object.entries(jsonData)) {
      players[key] = AdapterPlayer.decode(player, debugInfo + `.${key}`);
    }

    return players;
  }
}

export class AdapterFCMProtocolResponseSlots {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMProtocolResponseSlots {
    return {
      config: AdapterHelper.nullOrDecode<Data__SB_Config>(
        AdapterSBConfig.decode,
        jsonData.sb_config,
        debugInfo + ".sb_config",
      ),
      profile: AdapterHelper.nullOrDecode<Data__SB_UserProfile>(
        AdapterSBUserProfile.decode,
        jsonData.sb_user_profile,
        debugInfo + ".sb_user_profile",
      ),
      deltaProfile: AdapterHelper.nullOrDecode<IDataSBXDeltaProfile>(
        AdapterSBXDeltaProfile.decode,
        jsonData.sb_delta_user_profile,
        debugInfo + ".sb_delta_user_profile",
      ),
      inplayConflicts: AdapterHelper.nullOrDecodeArray<Data__SB_SportEvent>(
        AdapterEvent.decode,
        jsonData.active_inplay_conflicts,
        debugInfo + ".active_inplay_conflicts",
      ),
      prematchConflicts: AdapterHelper.nullOrDecodeArray<Data__SB_SportEvent>(
        AdapterEvent.decode,
        jsonData.active_prematch_conflicts,
        debugInfo + ".active_prematch_conflicts",
      ),
      prematchSubfeedsUpdate: AdapterFeedDiffUpdate.decode(
        jsonData.prematch_subfeeds_updates,
        debugInfo + ".prematch_subfeeds_updates",
      ),
      inplaySubfeedsUpdate: AdapterFeedDiffUpdate.decode(
        jsonData.inplay_subfeeds_updates,
        debugInfo + ".inplay_subfeeds_updates",
      ),
      players: AdapterPlayers.decode(jsonData.players, debugInfo + ".players"),
    };
  }
}

export class AdapterFCMRefreshUserProfileResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMRefreshUserProfileResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return { __object_class_name: "FCM__Refresh_User_Profile__Response" };
  }
}

class AdapterFCMReferralReportItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IReferralReportItem {
    return {
      user_id: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.user_id,
        debugInfo + ".user_id",
      ),
      username: ValidationUtils.assertNonEmptyString(
        jsonData.username,
        debugInfo + ".username",
      ),
      rewards_cash_earned_in_cents: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.rewards_cash_earned_in_cents,
        debugInfo + ".rewards_cash_earned_in_cents",
      ),
    };
  }
}

export class AdapterFCMGetReferralReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetReferralReportResponse {
    return {
      __object_class_name: "FCM__Get_Referral_Report__Response",
      max_rewards_cash_per_user: ValidationUtils.assertPositiveInteger(
        jsonData.max_rewards_cash_per_user,
        debugInfo + ".max_rewards_cash_per_user",
      ),
      total_rewards_cash_in_cents: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.total_rewards_cash_in_cents,
        debugInfo + ".total_rewards_cash_in_cents",
      ),
      data: AdapterHelper.decodeArray(
        AdapterFCMReferralReportItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterFCMTransaction {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMTransaction {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);
    const type = validateTransactionType(jsonData.type, debugInfo + ".type");

    // 2021-03-01 / Ivan / all transactions of type 4062__SLIDE_GIFT_CARD_CASHBACK
    // will have attached info for user gift card
    let giftCard: IDataSlideUserGiftCard | null = null;

    if (
      type === TransactionType.CONST_4062_SLIDE_GIFT_CARD_CASHBACK ||
      type ===
        TransactionType.CONST_4063_SLIDE_GIFT_CARD_PURCHASE_WITHOUT_CASHBACK
    ) {
      giftCard = AdapterSlideUserGiftCard.decode(
        jsonData.user_gift_card,
        debugInfo + ".user_gift_card",
      );
    }

    // 2021-03-01 / Ivan / all transactions of type CONST__4038__REFERRAL_PARENT_BONUS_FOR_REWARDS_CASH_PURCHASE_TOKENS_BUNDLE
    // will have attached info for referral source
    let referralData: I.IReferralReportItem | null = null;

    if (
      type ===
      TransactionType.CONST_4038_REFERRAL_PARENT_BONUS_FOR_REWARDS_CASH_PURCHASE_TOKENS_BUNDLE
    ) {
      referralData = AdapterFCMReferralReportItem.decode(
        jsonData.referral_data,
        debugInfo + ".referral_data",
      );
    }

    // 2021-05-20 / Ivan / we allow negative amount for some type of transactions
    let amountInCents: number;

    if (
      type ===
      TransactionType.CONST_4113_ADMIN_OPERATION_REWARDS_CASH_CORRECTION
    ) {
      amountInCents = ValidationUtils.assertAnyInteger(
        jsonData.amount_in_cents,
        debugInfo + ".amount_in_cents",
      );
    } else {
      amountInCents = ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_in_cents,
        debugInfo + ".amount_in_cents",
      );
    }

    return {
      id: ValidationUtils.assertPositiveInteger(jsonData.id, debugInfo + ".id"),
      type: type,
      created_timestamp_utc: ValidationUtils.assertPositiveInteger(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      // 2021-05-20 / Ivan / we allow negative amount for some type of transactions
      amount: amountInCents,
      obtained_coins: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.obtained_coins,
        debugInfo + ".obtained_coins",
      ),
      obtained_v2_tokens: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.obtained_v2_tokens,
        debugInfo + ".obtained_v2_tokens",
      ),
      obtained_fliff_cash: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.obtained_fliff_cash,
        debugInfo + ".obtained_fliff_cash",
      ),
      // 2021-06-25 / Ivan / moving to XP
      // 2021-06-26 / Ivan / may be negative - when you exchange for XP for data
      obtained_v3_xpoints: ValidationUtils.assertAnyInteger(
        jsonData.obtained_v3_xpoints,
        debugInfo + ".obtained_v3_xpoints",
      ),
      // may be negative
      d_5004_rewards_cash: ValidationUtils.assertAnyInteger(
        jsonData.d_5004_rewards_cash,
        debugInfo + ".d_5004_rewards_cash",
      ),
      user_gift_card: giftCard,
      referral_data: referralData,
    };
  }
}

export class AdapterFCMGetTransactionsReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetTransactionsReportResponse {
    return {
      __object_class_name: "FCM__Get_Transactions_Report__Response",
      transactions: AdapterHelper.decodeArray<I.IFCMTransaction>(
        AdapterFCMTransaction.decode,
        jsonData.transactions,
        debugInfo + ".transactions",
      ),
      unfinished_orders: AdapterHelper.decodeArray<IDataSBUnfinishedOrder>(
        AdapterSBUnfinishedOrder.decode,
        jsonData.unfinished_orders,
        debugInfo + ".unfinished_orders",
      ),
      sb_last_transaction_id: ValidationUtils.assertOptionalInteger(
        jsonData.sb_last_transaction_id,
        debugInfo + ".sb_last_transaction_id",
      ),
    };
  }
}

export class AdapterFCMInitiateTOUSession {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMInitiateTOUSessionResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Initiate_TOU_Session__Response",
      tou_session_enter_url: ValidationUtils.assertNonEmptyString(
        jsonData.tou_session_enter_url,
        debugInfo + ".tou_session_enter_url",
      ),
    };
  }
}

class AdapterFCMPaymentMethodToken {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPaymentMethodToken {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      payment_token: ValidationUtils.assertNonEmptyString(
        jsonData.payment_token,
        debugInfo + ".payment_token",
      ),
      visual_name_1: ValidationUtils.assertNonEmptyString(
        jsonData.visual_name_1,
        debugInfo + ".visual_name_1",
      ),
      visual_name_2: ValidationUtils.assertNonEmptyString(
        jsonData.visual_name_2,
        debugInfo + ".visual_name_2",
      ),
      visual_name_3: ValidationUtils.assertOptionalString(
        jsonData.visual_name_3,
        debugInfo + ".visual_name_3",
      ),
      instrument_type: validatePaymentMethodInstrumentType(
        jsonData.instrument_type,
        debugInfo + ".instrument_type",
      ),
      instrument_status: validatePaymentMethodInstrumentStatus(
        jsonData.instrument_status,
        debugInfo + ".instrument_status",
      ),
    };
  }
}

export class AdaptersFCMGetPaymentTokensResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetPaymentTokensResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_All_Payment_Tokens__Response",
      payment_tokens: AdapterHelper.decodeArray(
        AdapterFCMPaymentMethodToken.decode,
        jsonData.payment_tokens,
        debugInfo + ".payment_tokens",
      ),
    };
  }
}

export class AdaptersFCMDisablePaymentTokenResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMDisablePaymentTokenResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Disable_Payment_Token__Response",
      payment_tokens: AdapterHelper.decodeArray(
        AdapterFCMPaymentMethodToken.decode,
        jsonData.payment_tokens,
        debugInfo + ".payment_tokens",
      ),
    };
  }
}

export class AdapterFCMPublicFeedSyncResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPublicFeedSyncResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return { __object_class_name: "FCM__Public_Feed_Sync__Response" };
  }
}

export class AdaptersFCMGetContactUsPickSubject {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMContactUsPickSubject {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
    };
  }
}

export class AdaptersFCMGetContactUsOptionSubject {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMContactUsOptionSubject {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
    };
  }
}

export class AdaptersFCMGetContactUsSelectSubject {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMContactUsSelectSubject {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      type: ValidationUtils.assertNonEmptyString(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assertNonEmptyString(
        jsonData.name,
        debugInfo + ".name",
      ),
      subject_lines: AdaptersFCMGetContactUsSubjectLines.decode(
        jsonData.subject_lines,
        debugInfo + ".subject_lines",
      ),
    };
  }
}

export class AdaptersFCMGetContactUsSubjectLines {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.TFSMContactUsSubjectLine[] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return AdapterHelper.decodeArrayWithTypedItems(
      {
        pickId: AdaptersFCMGetContactUsPickSubject.decode,
        option: AdaptersFCMGetContactUsOptionSubject.decode,
        select: AdaptersFCMGetContactUsSelectSubject.decode,
      },
      jsonData,
      debugInfo + ".subject_lines",
    );
  }
}

export class AdaptersFCMGetContactUsSubjectLineResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetContactUsSubjectLineResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_Contact_US_Subject_Line__Response",
      subject_lines: AdaptersFCMGetContactUsSubjectLines.decode(
        jsonData.subject_lines,
        debugInfo + ".subject_lines",
      ),
    };
  }
}

export class AdapterFCMRegisterRadarSDKCallBackDataResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMRegisterRadarSDKCallBackDataResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Register_Radar_SDK_CallBack_Data__Response",
      usa_state_code: ValidationUtils.assertOptionalString(
        jsonData.usa_state_code,
        debugInfo + ".usa_state_code",
      ),
      usa_state_code_source: ValidationUtils.assertNonEmptyString(
        jsonData.usa_state_code_source,
        debugInfo + ".usa_state_code_source",
      ),
    };
  }
}

export class AdapterObtainBundleResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMObtainBundleResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return { __object_class_name: "FCM__Obtain_Bundle__Response" };
  }
}

class AdapterBundleContext {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IBundleContext {
    return {
      usa_state_code: ValidationUtils.assertOptionalString(
        jsonData.usa_state_code,
        debugInfo + ".usa_state_code",
      ),
      last_claim_stamp_sec: ValidationUtils.assertAnyInteger(
        jsonData.last_claim_stamp_sec,
        debugInfo + ".last_claim_stamp_sec",
      ),
      tags: AdapterHelper.decodeArrayOfStrings(
        jsonData.tags,
        debugInfo + ".tags",
      ),
      user_total_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.user_total_fliff_cash,
        debugInfo + ".user_total_fliff_cash",
      ),
      user_total_usd_spent: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.user_total_usd_spent,
        debugInfo + ".user_total_usd_spent",
      ),
    };
  }
}

class AdapterBundleGoods {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IBundleGoods {
    return {
      bundle_context: AdapterBundleContext.decode(
        jsonData.bundle_context,
        debugInfo + ".bundle_context",
      ),
      amount_fliff_cash: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_fliff_cash,
        debugInfo + ".amount_fliff_cash",
      ),
      bundle_code: ValidationUtils.assertNonEmptyString(
        jsonData.bundle_code,
        debugInfo + ".bundle_code",
      ),
      price_in_cents: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.price_in_cents,
        debugInfo + ".price_in_cents",
      ),
      amount_social_coins: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.amount_social_coins,
        debugInfo + ".amount_social_coins",
      ),
      applied_tags: AdapterHelper.decodeArrayOfStrings(
        jsonData.applied_tags,
        debugInfo + ".applied_tags",
      ),
      multiplier_in_percents: ValidationUtils.assertAnyInteger(
        jsonData.multiplier_in_percents,
        debugInfo + ".multiplier_in_percents",
      ),
    };
  }
}

export class AdapterInitiateBundleResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IInitiateBundlePurchaseResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Initiate_Bundle_Purchase__Response",
      initiate_url: ValidationUtils.assertNonEmptyString(
        jsonData.initiate_url,
        debugInfo + ".initiate_url",
      ),
      v9_bundle_goods_2_initiated: AdapterBundleGoods.decode(
        jsonData.v9_bundle_goods_2_initiated,
        debugInfo + ".v9_bundle_goods_2_initiated",
      ),
      own_invoice_ref_code: ValidationUtils.assertOptionalString(
        jsonData.own_invoice_ref_code || "",
        debugInfo + ".own_invoice_ref_code",
      ),
      own_account_ref_code: ValidationUtils.assertOptionalString(
        jsonData.own_account_ref_code || "",
        debugInfo + ".own_account_ref_code",
      ),
      own_invoice_id: ValidationUtils.assertAnyInteger(
        jsonData.own_invoice_id,
        debugInfo + ".own_invoice_id",
      ),
    };
  }
}

class AdapterPlayThroughPod {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayThroughPod {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      created_stamp_millis: ValidationUtils.assertPositiveIntegerOrZero(
        jsonData.created_stamp_millis,
        debugInfo + ".created_stamp_millis",
      ),
      creator_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.creator_fkey,
        debugInfo + ".creator_fkey",
      ),
      pod_fkey: ValidationUtils.assertNonEmptyString(
        jsonData.pod_fkey,
        debugInfo + ".pod_fkey",
      ),
      open_pick_fkeys: AdapterHelper.decodeArrayOfStrings(
        jsonData.open_pick_fkeys,
        debugInfo + ".open_pick_fkeys",
      ),
      r_7002_play_through_target: ValidationUtils.assertAnyInteger(
        jsonData.r_7002_play_through_target,
        debugInfo + ".r_7002_play_through_target",
      ),
      r_7003_play_through_progress: ValidationUtils.assertAnyInteger(
        jsonData.r_7003_play_through_progress,
        debugInfo + ".r_7003_play_through_progress",
      ),
      r_5011_v5_playable_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.r_5011_v5_playable_fliff_cash,
        debugInfo + ".r_5011_v5_playable_fliff_cash",
      ),
      r_7001_initial_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.r_7001_initial_fliff_cash,
        debugInfo + ".r_7001_initial_fliff_cash",
      ),
      r_5014_v5_transferred_fliff_cash: ValidationUtils.assertAnyInteger(
        jsonData.r_5014_v5_transferred_fliff_cash,
        debugInfo + ".r_5014_v5_transferred_fliff_cash",
      ),
      r_5012_v5_locked_in_picks_fliff_cash:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.r_5012_v5_locked_in_picks_fliff_cash,
          debugInfo + ".r_5012_v5_locked_in_picks_fliff_cash",
        ),
      r_7022_initial_multiplier_in_percents: ValidationUtils.assertAnyInteger(
        jsonData.r_7022_initial_multiplier_in_percents,
        debugInfo + ".r_7022_initial_multiplier_in_percents",
      ),
      v_transaction_amount: ValidationUtils.assertNonEmptyString(
        jsonData.v_transaction_amount,
        debugInfo + ".v_transaction_amount",
      ),
      v_transaction_type: ValidationUtils.assertOptionalString(
        jsonData.v_transaction_type,
        debugInfo + ".v_transaction_type",
      ),
      credible_state: validatePodCreditableState(
        jsonData.credible_state,
        debugInfo + ".credible_state",
      ),
      playable_state: validatePodPlayableState(
        jsonData.playable_state,
        debugInfo + ".playable_state",
      ),
      progress_state: validatePodProgressState(
        jsonData.progress_state,
        debugInfo + ".progress_state",
      ),
    };
  }
}

export class AdapterGetPodsReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetPodsReportResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_Pods_Report__Response",
      playable_pods: AdapterHelper.decodeArray(
        AdapterPlayThroughPod.decode,
        jsonData.playable_pods,
        debugInfo + ".playable_pods",
      ),
      merge_code: ValidationUtils.assertPositiveInteger(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
    };
  }
}

class AdapterPlacePickOperationStatus {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPlacePickResponse["place_picks_operation_status"] {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      required_confirmed_server_quote_coeff: ValidationUtils.assertAnyInteger(
        jsonData.required_confirmed_server_quote_coeff,
        debugInfo + ".required_confirmed_server_quote_coeff",
      ),
      status: validatePlacePicksOperationStatus(
        jsonData.status,
        debugInfo + ".status",
      ),
      required_resubmit_after_timestamp_millis:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.required_resubmit_after_timestamp_millis,
          debugInfo + ".required_resubmit_after_timestamp_millis",
        ),
      required_resubmit_delay_millis:
        ValidationUtils.assertPositiveIntegerOrZero(
          jsonData.required_resubmit_delay_millis,
          debugInfo + ".required_resubmit_delay_millis",
        ),
    };
  }
}

export class AdapterFCMPlacePickResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPlacePickResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Place_Pick__Response",
      picks: AdapterHelper.decodeArray<IDataSBPick>(
        AdapterSBPick.decode,
        jsonData.picks,
        debugInfo + ".picks",
      ),
      place_picks_operation_status: AdapterPlacePickOperationStatus.decode(
        jsonData.place_picks_operation_status,
        debugInfo + ".place_picks_operation_status",
      ),
    };
  }
}

export class AdapterGetPicksReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetPicksReportResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_Picks_Report__Response",
      picks: AdapterHelper.decodeArray(
        AdapterSBPick.decode,
        jsonData.picks,
        debugInfo + ".picks",
      ),
      merge_code: ValidationUtils.assertPositiveInteger(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
    };
  }
}

export class AdapterFCMGetPublicFantasyFeedSnapshotResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetPublicFantasyFeedSnapshotResponse {
    ValidationUtils.assertNonNullData(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_Public_Fantasy_Feed_Snapshot__Response",
      data: jsonData,
    };
  }
}
