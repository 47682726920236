import { initialState } from "./social.initialState";
import { ISocialState, TAction } from "./types";
import {
  SocialInjectProtocolSlotsAction,
  SocialSetMultipleProfilesAction,
  SocialSetUserAction,
} from "./social.actions";

const processInjectedSlots = (
  state: ISocialState,
  action: SocialInjectProtocolSlotsAction,
): ISocialState => {
  const nextState = { ...state };

  if (action.payload.social_config) {
    nextState.socialConfig = action.payload.social_config;
  }

  // @todo - add during chat development
  // if (nextState.socialConfig.chat_token_endpoint) {
  //   SocialApiUrlManager.setLastKnownUrls(
  //       nextState.socialConfig.chat_token_endpoint,
  //   );
  // }

  return nextState;
};

const socialReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case SocialSetUserAction.type:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.profile.user_fkey]: {
            ...state.users[action.payload.profile.user_fkey],
            ...action.payload,
          },
        },
      };
    case SocialInjectProtocolSlotsAction.type:
      return processInjectedSlots(state, action);
    case SocialSetMultipleProfilesAction.type:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload.reduce((acc, profile) => {
            return {
              ...acc,
              [profile.user_fkey]: {
                ...state.users[profile.user_fkey],
                profile,
              },
            };
          }, {}),
        },
      };
    default:
      return state;
  }
};

export default socialReducer;
