import "./styles.scss";
import { IProps } from "./types";
import classNames from "classnames";
import FliffSpinner from "assets/json/fliff-spinner.json";
import Lottie from "lottie-react";

const LoadingSpinner = ({ size, className }: IProps) => {
  const loadingSpinnerClasses = classNames("loading-spinner", className, {
    [`loading-spinner--${size}`]: size,
  });

  return (
    <Lottie
      autoplay
      loop
      animationData={FliffSpinner}
      className={loadingSpinnerClasses}
    />
  );
};

export default LoadingSpinner;
