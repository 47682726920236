import React from "react";
import classNames from "classnames";
import InputMask from "react-input-mask";

import "./styles.scss";
import { IProps } from "./types";
import ValidationStateMark from "components/atoms_new/shared/Inputs/atoms/ValidationStateMark";

const DateOfBirthInput = ({
  onChangeText,
  disabled,
  className,
  validationState,
  ...props
}: IProps) => {
  const isInvalidState = validationState.state === 0;

  const inputContainerClasses = classNames(
    "input-container-regular",
    className,
    {
      "input-container-regular--invalid": isInvalidState,
    },
  );

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeText?.(e.currentTarget.value);
  };

  return (
    <div className={inputContainerClasses}>
      <InputMask
        mask="99/99/9999"
        placeholder={"Date of Birth: MM/DD/YYYY"}
        inputMode="numeric"
        onChange={handleOnChange}
        disabled={disabled}
        {...props}
      />

      <ValidationStateMark validationState={validationState} />
    </div>
  );
};

export default DateOfBirthInput;
