import Lottie from "lottie-react";
import { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";

import "./styles.scss";
import { ISuccessPurchaseModalService } from "./types";

import Logo from "assets/img/FliffLogoMixed.png";
import GlowBackground from "assets/json/glow.json";
import FliffCashBox from "components/molecules/mobile/Modals/SuccessPurchaseModal/atoms/FliffCashBox";
import FliffCurrenciesBox from "components/molecules/mobile/Modals/SuccessPurchaseModal/atoms/FliffCurrenciesBox";
import { useHistory } from "react-router-dom";
import AppSelectors from "reduxLocal/app/app.selectors";
import { IPlayThroughBundle } from "server/legacyCore/data/objects";
import { CoinsAmountFormatters } from "utils/UIAmountsFormatters";
import { BUNDLE_OFFER_SECONDARY_SKIN_MAP } from "constants/BUNDLES";
import Button from "components/atoms/mobile/Button";

const successPurchaseModalRef = createRef<ISuccessPurchaseModalService>();

export const SuccessPurchaseModalService = {
  ref: successPurchaseModalRef,
  onShow: (bundle: IPlayThroughBundle | null) =>
    successPurchaseModalRef.current?.onShow(bundle),
};

const SuccessPurchaseModal = forwardRef<ISuccessPurchaseModalService>(
  (_props, ref) => {
    const [{ isVisible, bundle }, setConfig] = useState<{
      isVisible: boolean;
      bundle: IPlayThroughBundle | null;
    }>({ isVisible: false, bundle: null });
    const isAuthenticated = useSelector(AppSelectors.isAuthenticated);
    const history = useHistory();
    const handleModalHide = (): void => {
      setConfig({ isVisible: false, bundle: null });
      history.replace("/shop");
    };

    useImperativeHandle(
      ref,
      () => ({
        onShow: (nextBundle: IPlayThroughBundle | null) =>
          setConfig({ isVisible: true, bundle: nextBundle }),
      }),
      [],
    );

    if (!isVisible || !bundle || !isAuthenticated) {
      return null;
    }

    return (
      <section className="success-purchase-modal">
        <div className="success-purchase-modal__entry">
          <img
            src={Logo}
            alt="Fliff logo"
            className="success-purchase-modal__logo"
          />
          <h1 className="success-purchase-modal__title">
            {`You purchased ${CoinsAmountFormatters.toGoldCoins(
              bundle.amount_social_coins,
            )}\nFliff Coins`}
          </h1>
        </div>
        <div className="success-purchase-modal__background">
          <Lottie
            loop
            autoPlay
            animationData={GlowBackground}
            className="success-purchase-modal__glow"
          />
          <img
            className="success-purchase-modal__image"
            src={BUNDLE_OFFER_SECONDARY_SKIN_MAP[bundle.level_1_skin]}
            alt="Fliff bundle offer"
          />
        </div>
        <FliffCashBox fliffCashAmount={bundle.amount_fliff_cash} />
        <FliffCurrenciesBox fliffCashAmount={bundle.amount_fliff_cash} />
        <Button
          onClick={handleModalHide}
          label="Done"
          type="gold"
          color="purple"
        />
      </section>
    );
  },
);

SuccessPurchaseModal.displayName = "SuccessPurchaseModal";

export default SuccessPurchaseModal;
