import { createContext, useContext } from "react";
import { Data__SB_Channel } from "server/legacyCore/data/objects";

interface ISportChannelsContext {
  handleChannelSelect: (id: number, fromAllSports?: boolean) => void;
  selectedChannelId: number;
  selectedSubChannelId: number;
  handleSubChannelSelect: (id: number) => void;
  catchallChannel: Data__SB_Channel | null;
}

const sportsChannelsContext = createContext<ISportChannelsContext>({
  handleChannelSelect: () => ({}),
  handleSubChannelSelect: () => ({}),
  selectedChannelId: 0,
  selectedSubChannelId: 0,
  catchallChannel: null,
});

export const useSportEvents = (): ISportChannelsContext =>
  useContext<ISportChannelsContext>(sportsChannelsContext);

export default sportsChannelsContext;
