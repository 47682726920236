import {
  SocialAccessLevel,
  SocialUserRelationStatus,
  SocialUserType,
} from "server/social/data/constants";

class SocialUtils {
  public checkIsUserFriend = (
    relationStatus: SocialUserRelationStatus,
  ): boolean => relationStatus === SocialUserRelationStatus.CONST_4233_FRIEND;

  public checkIsUserFollower = (
    relationStatus: SocialUserRelationStatus,
  ): boolean => relationStatus === SocialUserRelationStatus.CONST_4231_FOLLOWER;

  public checkIsUserFollowing = (
    relationStatus: SocialUserRelationStatus,
  ): boolean =>
    relationStatus === SocialUserRelationStatus.CONST_4232_FOLLOWING;

  public checkIsUserNotRelated = (
    relationStatus: SocialUserRelationStatus,
  ): boolean => relationStatus === SocialUserRelationStatus.CONST_4239_NONE;

  public checkIsFriendshipRelated = (
    relationStatus: SocialUserRelationStatus,
  ): boolean =>
    this.checkIsUserFriend(relationStatus) ||
    this.checkIsUserFollowing(relationStatus);

  public checkIsUserBlocked = (accessLevel: SocialAccessLevel): boolean =>
    accessLevel === SocialAccessLevel.CONST_5079_BLOCKED;

  public toValidUserFkey = (id: number | string): string =>
    typeof id === "number" ? `fobj__sb_user_profile__${id}` : id;
  public toValidUserId = (userFKey: string): number =>
    +userFKey.replace("fobj__sb_user_profile__", "");

  public isUnranked = (rank: number): boolean => rank < 1;

  public isInfluencer = (type: SocialUserType | undefined): boolean =>
    type === SocialUserType.CONST_7132_INFLUENCER;
}

export default new SocialUtils();
