import { ICoreState } from "./types";

import {
  IdentityVerificationStatus,
  PickType,
  PlacePickPlayThroughMode,
  UIConstants,
  UserAccountDetailsStatus,
} from "server/legacyCore/data/constants";
import {
  Data__SB_Config,
  Data__SB_Permissions_And_Common_Data,
  Data__SB_User_Personal_Info,
} from "server/legacyCore/data/objects";
import { Data__SB_ShoppingCart } from "server/legacyCore/data/objectsLocal";
import { AppUtils } from "utils/AppUtils";

export const initialState: ICoreState = {
  events: [],
  last_prematch_conflicts: [],
  last_inplay_conflicts: [],
  bet_supermarket: { shelves_by_conflict_fkey: {} },
  eventStats: {},
  players: {},
  config: AppUtils.zDeepFreeze<Data__SB_Config>({
    obj_version: -1,
    reward_cash_per_referral_in_cents: -1,
    min_user_profile_id_which_requires_kyc_before_place_pick: -1,

    max_number_of_selections_total: -1,
    max_number_of_selections_in_straight_mode: -1,
    max_number_of_selections_in_parlay_mode: -1,
    max_number_of_selections_in_sgp_mode: -1,
    max_number_of_selections_in_sgp_plus_mode: -1,

    fliff_cash_straight__min_pick_amount_in_cents: -1,
    fliff_cash_straight__max_pick_amount_in_cents: -1,
    fliff_cash_straight__max_payout_in_cents: -1,
    fliff_cash_parlay__min_pick_amount_in_cents: -1,
    fliff_cash_parlay__max_pick_amount_in_cents: -1,
    fliff_cash_parlay__max_payout_in_cents: -1,

    credits_straight__min_pick_amount_in_cents: -1,
    credits_straight__max_pick_amount_in_cents: -1,
    credits_straight__max_payout_in_cents: -1,
    credits_parlay__min_pick_amount_in_cents: -1,
    credits_parlay__max_pick_amount_in_cents: -1,
    credits_parlay__max_payout_in_cents: -1,

    // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
    xp_bonus_progress__amount_required_for_bonus: -1,
    xp_bonus_progress__amount_bonus: -1,

    xp_bonus_referral__max_amount_per_child: -1,
    xp_bonus_referral__percent_from_child_purchase: -1,

    channels: [],

    v3_xpoints_for_complete_single_challenge: -1,
    v3_xpoints_for_complete_all_daily_challenges: -1,

    error_codes_without_modal_error: [],
    // 2022-04-27 / Ivan / add support for SGP quote fatal error
    error_codes_for_fatal_sqp_quote_error: [],
    error_codes_for_hide_in_ticket_sqp_error: [],
    onboarding_ad_amount_bonus: 0,
    onboarding_ad_amount_purchase: 0,
    max_cashback_in_promiles_for_slide: 0,
    conflict_classes: [],
    default_pdata: Data__SB_Permissions_And_Common_Data.EMPTY,
    amount_which_requires_level_2_verification: 0,

    referral_bonus__percent_from_child_purchase: 0,
    referral_bonus__text_1: "",
    referral_bonus__text_2: "",
    server_config__text_11: "",
    server_config__text_21: "",
    server_config__text_41: "",
    auth_mode: 0,

    rpm_status_check_url: "",
    rpm_status_check_substring: "",
    min_amount_for_withdraw_in_cents: 0,
  }),

  points_transaction_report: {
    period_request_id: "",
    period_start_stamp_seconds_utc: -1,
    period_end_stamp_seconds_utc: -1,
    data: [],
  },
  today_challenges_report: {
    period_type: 0,
    period_start_stamp_seconds_utc: 0,
    period_visual_start_date: "",
    period_visual_end_date: "",
    report_anchor_stamp_seconds_utc: 0,
    report_anchor_offset: 0,
    has_prev: false,
    has_next: false,
    is_today: false,
    error_message: "",
    data: [],
  },
  x_delta_profile: {
    // 2021-02-12 / Ivan / added additional user properties that may also depend on time
    user_pdata: Data__SB_Permissions_And_Common_Data.EMPTY,

    d_51207_last_v4_xpoints_bonus: null,
    d_51206_last_v2_completed_daily_challenge: null,
    d_51221_last_v2_transaction_id: 0,
    d_51250_last_transaction_4115_mail_in_entry: null,
    d_51251_last_transaction_4116_pending_fc_credit: null,
    d_51252_last_transaction_4117_cleared_fc_credit: null,

    // 2021-03-28 / Ivan / communicate purchase result back to mobile app
    d_51231_last_v3_purchase_error_order: null,
    d_51232_last_v3_purchase_success_transaction: null,
    d_51241_last_v3_order_updates_tracker: 0,
  },
  slide_card_brands: {
    brands: [],
  },
  profile: {
    user_id: -1,
    obj_version: -1,

    amount_v2_tokens: 0,
    amount_v3_xpoints: 0,
    amount_max_v3_xpoints_for_place_pick: 0,
    r_5011_v5_playable_fliff_cash: 0,
    r_5013_v5_redeemable_fliff_cash: 0,
    v_total_balance_on_home_screen: "",
    place_pick_play_through_mode:
      PlacePickPlayThroughMode.CONST_4501_CAN_PLACE_PICK_PLAYABLE_AND_REDEEMABLE,
    // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
    amount_xp_bonus_progress_tokens: 0,
    sb_affiliate_hash: "",
    open_iap_purchases_count: -1,
    open_orders_count: -1,
    may_display_rate_us_nag: false,
    may_display_rate_us_reason: "",
    fc_limits: [],
    // 2021-01-11 / Ivan / introduce v2 withdraw limits
    // all values are -1 if there are no limits imposed
    v2_withdraw_limits_allowed_amount: -1,
    last_withdraw_visual_date: "",
    next_withdraw_min_visual_date: "",
    next_withdraw_days_left: -1,

    this_week_stats: {
      s_picks_count: 0,
      s_5005_v2_tokens: 0,
      s_5007_v2_credits: 0,
      s_5009_v3_xpoints: 0,
      s_6011_best_win: 0,
      s_6021_skill_coeff: 0,
    },
    prev_week_stats: {
      s_picks_count: 0,
      s_5005_v2_tokens: 0,
      s_5007_v2_credits: 0,
      s_5009_v3_xpoints: 0,
      s_6011_best_win: 0,
      s_6021_skill_coeff: 0,
    },
    attributes: [],
    referrer_id: 0,
    referrer_username: "",
    own_bonus_code: "",
    // 2021-12-17 / Ivan / move survey config to server
    survey_title: "",
    survey_logo_url: "",
    survey_description: "",
    survey_button_label: "",
    survey_url: "",
    survey_webview_title: "",
    // 2021-10-27 / Ivan / add protocol support for pending withdraws
    d_51071_withdraw__locked_amount: -1,
    identity_verification_status: IdentityVerificationStatus.CONST_7000_LEVEL_0,
    d_51073_withdraw__total_approved_amount: -1,
    d_51072_withdraw__locked_count: -1,
    d_51002_tokens_active_picks_count: -1,
    d_51003_fliff_cash_locked_amount: -1,
    d_51004_fliff_cash_active_picks_count: -1,
    pick_delay_acceptance_timeout_millis: 99999,
    // 2021-12-16 / Ivan / move withdraw limits to use profile
    withdraw_7303__CASH_APP__max_amount: -2,
    withdraw_7304__SKRILL__max_amount: -2,
    withdraw_7305__BITCOIN__max_amount: -2,
    withdraw_7306__MAZOOMA_VERIFIED__max_amount: -2,
    withdraw_7307__MAZOOMA_INSTANT__max_amount: -2,
    withdraw_7308__FISERV_VERIFIED__max_amount: -2,
    withdraw_min_days_between_withdraws: -2,
    withdraw_max_days_to_process_withdraw: -2,
    personal_info: Data__SB_User_Personal_Info.EMPTY,
    username: "none",
    // 2022-08-05 / Ivan / introduce server side control over available methods for bundle purchase
    avalable_payment_vendors: [],
    // 2022-10-26 / Ivan / added some stats in order to help mobile app to track first pick/purchase
    s_1_total_picks: 0,
    s_3_total_money_in_purchase_count: 0,
    // 2022-12-20 / Ivan / introduce server side control over account details verification
    account_details_status:
      UserAccountDetailsStatus.CONST_UNSET_ACCOUNT_DETAILS_COMPLETED_READ_ONLY, // as TUser_Account_Details_Status,
  },
  lastTransactionsReport: { transactions: [], unfinishedOrders: [] },
  paymentTokens: [],
  proposals: {},
  shopping_cart: AppUtils.zDeepFreeze<Data__SB_ShoppingCart>({
    items: {},
    unique_shopping_cart_id: "need_to_be_randomly_generated_on_cart_reset",
    totalPlacedGoldCoinsAmount: 0,
    totalPlacedFliffCashAmount: 0,
    pickType: PickType.CONST_81_STRAIGHT,
    sourcePick: null,
  }),

  shopping_cart_ui: AppUtils.zDeepFreeze({
    ui_currency_mode: UIConstants.CONST__333__FLIFF_COINS,
    sgpStatusData: {
      totalCoeff: 0,
      shouldRequestNewCoeff: false,
      coeffOnSubmitDidChange: false,
    },
    isTicketProcessing: false,
  }),

  pdata: Data__SB_Permissions_And_Common_Data.EMPTY,
  last_default_pdata: null,
  last_user_pdata: null,
  isAltPropButtonEnabled: false,
};
